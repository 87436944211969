enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  CHANGE_PASSWORD = "changePassword",
  RESET_PASSWORD = "resetPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  CREATE_ORGANIZATION = "createOrganization",
  DELETE_ORGANIZATION = "deleteOrganization",
  CREATE_PROJECT = "createProject",
  DELETE_PROJECT = "deleteProject",
  DELETE_PROJECTS_BULK = "deleteProjectsBulk",
  LOAD_ORGANIZATIONS = "loadOrganizations", // no parameters
  READ_ORGANIZATIONS = "readOrganizations", // with parameters
  LOAD_STATIC_ORGS = "loadStaticOrgs",
  LOAD_ORGANIZATION = "loadOrganization",
  LOAD_ALL_PROJECTS = "loadAllProjects",
  LOAD_ALL_PROJECTS_OF_TYPE = "loadAllProjectsOfType",
  LOAD_PROJECTS_FOR_ORG = "loadProjectsForOrg",
  LOAD_STATIC_PROJECTS = "loadStaticProjects",
  LOAD_MY_PROJECTS = "loadMyProjects",
  LOAD_PROJECT = "loadProject",
  LOAD_PROJECT_NAMES = "loadProjectNames",
  LOAD_PROJECT_SHARED = "loadProjectShared",
  LOAD_PROJECT_FILES_SHARED = "loadProjectFilesShared",
  CREATE_SHARE_LINK = "createShareLink",
  UPDATE_PROJECT = "updateProject",
  UPDATE_ORGANIZATION = "updateOrganization",
  LOAD_OTHER_USERS = "loadOtherUsers",
  LOAD_OTHER_USER = "loadOtherUser",
  LOAD_USERS_IN_ORG = "loadUsersInOrg",
  UPLOAD_FILE = "uploadFile",
  UPLOAD_DRAWING = "uploadDrawing",
  DELETE_FILE = "deleteFile",
  DELETE_FILES_BULK = "deleteFilesBulk",
  DELETE_DRAWING = "deleteDrawing",
  DELETE_DRAWINGS_BULK = "deleteDrawingsBulk",
  // UPLOAD_IMAGE = "uploadImage",
  STORE_FILE_DATA = "storeFileData",
  UPDATE_FILE_DATA = "updateFileData",
  STORE_DRAWING_DATA = "storeDrawingData",
  UPLOAD_CSV_FILE = "uploadCsvFile",
  LOAD_PROJECT_TYPES = "loadProjectTypes",
  LOAD_SECTORS = "loadSectors",
  LOAD_DOC_TYPES = "loadDocTypes",
  LOAD_DRAWING_TYPES = "loadDrawingTypes",
  LOAD_FILES = "loadFiles",
  LOAD_DRAWINGS = "loadDrawings",
  LOAD_DRAWINGS_BY_IDS = "loadDrawingsByIds",
  LOAD_PROJECT_FILES = "loadProjectFiles",
  LOAD_PROJECT_DRAWINGS = "loadProjectDrawings",
  // POPULATE_FILES = "populateFiles",
  GET_ORPHAN_USERS = "getOrphanUsers",
  ADD_ORG_MEMBER = "addOrgMember",
  REMOVE_ORG_MEMBER = "removeOrgMember",
  ADD_ADMIN = "addAdmin",
  REMOVE_ADMIN = "removeAdmin",
  ADD_TEAM_MEMBER = "addTeamMember",
  REMOVE_TEAM_MEMBER = "removeTeamMember",
  CHANGE_ACCOUNT_TYPE = "changeAccountType",
  CREATE_ACTIVITY = "createActivity",
  UPDATE_ACTIVITY = "updateActivity",
  DELETE_ACTIVITY = "deleteActivity",
  LOAD_ACTIVITY_RESPONSIBLE = "loadActivityResponsible",
  READ_BOOKMARKS = "readBookmarks",
  ADD_BOOKMARKS = "addBookmarks",
  REMOVE_BOOKMARKS = "removeBookmarks",
  GET_SHARE_TOKEN_DATA = "getShareTokenData",
  AI_MAKE_QUERY = "aiMakeQuery",
  AI_ABORT_QUERY = "aiAbortQuery",
  AI_GET_CHAT_HISTORY = "aiGetChatHistory",
  AI_DELETE_CHAT_HISTORY = "aiDeleteChatHistory",
  AI_SEARCH_DRAWINGS = "aiSearchDrawings",
  LOAD_EXPERTS = "loadExperts",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  UPDATE_SET_USER = "updateSetUser",
  // SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_OTHER_USERS = "setOtherUsers",
  SET_ORGANIZATIONS = "setOrganizations",
  SET_ORGANIZATION = "setOrganization",
  SET_MY_PROJECTS = "setMyProjects",
  SET_PROJECTS = "setProjects",
  SET_BOOKMARKS = "setBookmarks",
  SET_FILES = "setFiles",
  SET_DRAWINGS = "setDrawings",
  SET_PROJECT_TYPES = "setProjectTypes",
  SET_SECTORS = "setSectors",
  LOADING_ON = "loadingOn",
  LOADING_OFF = "loadingOff",
  SET_EXPERTS = "setExperts",
}

export { Actions, Mutations };
