import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store/";

export interface OtherUser {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNo: string;
  address: string;
  avatarUrl: string;
  organization: Organization;
}

export interface Organization extends Record<string, unknown> {
  _id: string;
  name: string;
  users: OtherUser[];
  admins: OtherUser[];
  projects: Project[];
}

export interface Project extends Record<string, unknown> {
  _id: string;
  name: string;
  description: string;
  type: string;
  sector: string;
  organization: Organization;
  budget: Budget;
  startDate: string;
  activities: Activity[];
  team: OtherUser[];
  logo: string;
  archived: boolean;
}

export interface Activity {
  _id: string;
  name: string;
  description: string;
  keyword: string;
  status: string;
  deadline: string;
  responsible: OtherUser;
  project: Project;
}

export interface ConstructionFile extends Record<string, unknown> {
  _id: string;
  name: string;
  url: string;
  // type: string;
  documentType: string;
  year: string;
  // organization: Organization;
  project: Project;
}

export interface DrawingFile extends Record<string, unknown> {
  _id: string;
  name: string;
  url: string;
  previewUrl: string;
  drawingType: string;
  // year: string;
  // organization: Organization;
  project: Project;
}

export interface Budget {
  _id: string;
  total: number;
  spent: number;
  notes: string;
}

export interface Data {
  error: unknown; // todo: maybe
  otherUsers: OtherUser[];
  organizations: OrganizationsObject;
  sectors: StringArrayDataObject;
  projectTypes: StringArrayDataObject;
  projects: PaginatedProjectsObject;
  myProjects: PaginatedProjectsObject;
  // activities: Activity[];
}

export interface PaginatedData {
  limit: number;
  skip: number;
  total: number;
  result: Record<string, unknown>[];
}
export class Projects implements PaginatedData {
  limit = 0;
  skip = 0;
  total = 0;
  result: Project[] = [] as Project[];
}
export class ConstructionFiles implements PaginatedData {
  limit = 0;
  skip = 0;
  total = 0;
  result: ConstructionFile[] = [] as ConstructionFile[];
}
export class DrawingFiles implements PaginatedData {
  limit = 0;
  skip = 0;
  total = 0;
  result: DrawingFile[] = [] as DrawingFile[];
}

export interface IArrayDataObject {
  data: unknown[] | PaginatedData;
  fetched: boolean;
  promise: Promise<unknown> | undefined;
  error: string | undefined;
}

export class StringArrayDataObject implements IArrayDataObject {
  data: string[] = [] as string[];
  fetched = false;
  promise = undefined;
  error: string | undefined;
}

export class ObjectArrayDataObject implements IArrayDataObject {
  data: Record<string, unknown>[] = [] as Record<string, unknown>[];
  fetched = false;
  promise = undefined;
  error: string | undefined;
}
export class OrganizationsObject extends ObjectArrayDataObject {
  data: Organization[] = [] as Organization[];
}

export class PaginatedArrayDataObject implements IArrayDataObject {
  data: PaginatedData = {} as PaginatedData;
  fetched = false;
  promise = undefined;
  error: string | undefined;
}
export class PaginatedProjectsObject extends PaginatedArrayDataObject {
  data: Projects = new Projects();
}

export class Bookmarks {
  projects: string[] = [];
  files: string[] = [];
}

@Module
export default class DataModule extends VuexModule implements Data {
  error = null;
  otherUsers = [] as OtherUser[];
  organizations: OrganizationsObject = new OrganizationsObject();
  organization = {} as Organization; // the logged in user's organization
  projects: PaginatedProjectsObject = new PaginatedProjectsObject();
  bookmarks = new Bookmarks();
  myProjects: PaginatedProjectsObject = new PaginatedProjectsObject();
  fileCache: Record<string, ConstructionFile[]> = {} as Record<
    string,
    ConstructionFile[]
  >;
  totalFileCount: Record<string, number> = {} as Record<string, number>;
  drawingCache: Record<string, DrawingFile[]> = {} as Record<string, DrawingFile[]>;
  totalDrawingCount: Record<string, number> = {} as Record<string, number>;
  projectTypes = new StringArrayDataObject();
  sectors = new StringArrayDataObject();

  get getOtherUsers(): OtherUser[] {
    // todo: need to fetch list of users at SOME point ...
    return this.otherUsers;
  }

  get getOrganizations(): Organization[] {
    return this.organizations.data;
  }

  get getOrganization(): Organization {
    return this.organization;
  }

  get getProjects(): Projects {
    return this.projects.data;
  }

  get getProjectsError(): string {
    return this.projects.error || "";
  }

  get getBookmarksProjects(): string[] {
    return this.bookmarks.projects;
  }

  get getBookmarksFiles(): string[] {
    return this.bookmarks.files;
  }

  get getMyProjects(): Projects {
    return this.myProjects.data;
  }

  get getProjectTypes(): string[] {
    return this.projectTypes.data;
  }

  get getSectors(): string[] {
    return this.sectors.data;
  }

  get getDataError() {
    return this.error;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.error = error;
  }

  @Mutation
  [Mutations.SET_OTHER_USERS](otherUsers) {
    this.otherUsers = otherUsers;
  }

  @Mutation
  [Mutations.SET_ORGANIZATIONS](obj: OrganizationsObject) {
    const { data, promise, fetched, error } = obj || {};
    if (!obj) this.organizations = new OrganizationsObject();
    if (data) this.organizations.data = data;
    this.organizations.promise = promise;
    this.organizations.fetched = fetched;
    this.organizations.error = error;
  }

  @Mutation
  [Mutations.SET_ORGANIZATION](organization) {
    this.organization = organization;
  }

  @Mutation
  [Mutations.SET_MY_PROJECTS](obj: PaginatedProjectsObject) {
    const { data, promise, fetched, error } = obj || {};
    if (!obj) this.myProjects = new PaginatedProjectsObject();
    if (data) this.myProjects.data = data;
    this.myProjects.promise = promise;
    this.myProjects.fetched = fetched;
    this.myProjects.error = error;
  }

  @Mutation
  [Mutations.SET_PROJECTS](obj: PaginatedProjectsObject) {
    const { data, promise, fetched, error } = obj || {};
    if (!obj) this.projects = new PaginatedProjectsObject();
    if (data) this.projects.data = data;
    this.projects.promise = promise;
    this.projects.fetched = fetched;
    this.projects.error = error;
  }

  @Mutation
  [Mutations.SET_FILES](obj) {
    if (!obj) {
      this.fileCache = {} as Record<string, ConstructionFile[]>;
      return;
    }
    const { data, skip, limit, filter } = obj;
    if (this.fileCache[filter].length <= skip) {
      const nMissing = skip + limit - this.fileCache[filter].length;
      this.fileCache[filter] = this.fileCache[filter].concat(
        new Array(nMissing).fill(undefined)
      );
    }
    this.totalFileCount[filter] = data.total;
    this.fileCache[filter].splice(skip, limit, ...data.result);
  }

  @Mutation
  [Mutations.SET_DRAWINGS](obj) {
    if (!obj) {
      this.drawingCache = {} as Record<string, DrawingFile[]>;
      return;
    }
    const { data, skip, limit, filter } = obj;
    if (this.drawingCache[filter] && this.drawingCache[filter].length <= skip) {
      const nMissing = skip + limit - this.drawingCache[filter].length;
      this.drawingCache[filter] = this.drawingCache[filter].concat(
        new Array(nMissing).fill(undefined)
      );
    }
    this.totalDrawingCount[filter] = data.total;
    this.drawingCache[filter]?.splice(skip, limit, ...data.result);
  }

  @Mutation
  [Mutations.SET_PROJECT_TYPES](obj) {
    const { data, promise, fetched, error } = obj || {};
    if (!obj) this.projectTypes = new StringArrayDataObject();
    this.projectTypes.data = data;
    this.projectTypes.promise = promise;
    this.projectTypes.fetched = fetched;
    this.projectTypes.error = error;
  }

  @Mutation
  [Mutations.SET_SECTORS](obj) {
    const { data, promise, fetched, error } = obj || {};
    if (!obj) this.sectors = new StringArrayDataObject();
    this.sectors.data = data;
    this.sectors.promise = promise;
    this.sectors.fetched = fetched;
    this.sectors.error = error;
  }

  @Mutation
  [Mutations.SET_BOOKMARKS]({ ids, type }) {
    this.bookmarks[type] = ids;
  }

  @Action
  [Actions.LOAD_ORGANIZATIONS](refetch) {
    if (!refetch && this.organizations.fetched) return;
    if (!refetch && this.organizations.promise) return this.organizations.promise;
    const promise = ApiService.get("organization", "")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ORGANIZATIONS, {
          data,
          fetched: true,
        });
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ORGANIZATIONS, {
          error: response.data?.error,
          fetched: false,
        });
      });
    this.context.commit(Mutations.SET_ORGANIZATIONS, {
      promise,
    });
  }

  @Action
  [Actions.LOAD_STATIC_ORGS]() {
    return ApiService.get("constructionfile?distinct=organization", "")
      .then(({ data }) => {
        return { success: true, data: data.result };
      })
      .catch(({ response }) => {
        return {
          success: false,
          error: response.data?.error,
        };
      });
  }

  @Action
  [Actions.LOAD_STATIC_PROJECTS]() {
    return ApiService.get("drawingfile?distinct=projectName", "")
      .then(({ data }) => {
        return { success: true, data: data.result };
      })
      .catch(({ response }) => {
        return {
          success: false,
          error: response.data?.error,
        };
      });
  }

  @Action
  [Actions.READ_ORGANIZATIONS](args) {
    const { publicGet, populate } = args;
    const query = args.query && encodeURIComponent(args.query);
    const popQuery = populate ? `?populate=${populate}` : "";
    const queryQuery = query ? `&query=${query}` : "";
    return ApiService.get(
      (publicGet ? "public/" : "") + `organization${popQuery}${queryQuery}`,
      ""
    )
      .then(({ data }) => {
        return { success: true, data };
      })
      .catch(({ response }) => {
        return {
          success: false,
          error: response.data?.error,
        };
      });
  }

  @Action
  [Actions.LOAD_ORGANIZATION](orgId) {
    if (!orgId) {
      this.context.commit(Mutations.SET_ORGANIZATION, null);
      return;
    }
    return ApiService.get(
      "organization/" + orgId + "?populate=users,admins,waitlist&select=name",
      ""
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ORGANIZATION, data);
      })
      .catch(({ response }) => {
        if (response.data.error)
          this.context.commit(Mutations.SET_ERROR, response.data.error);
      });
  }

  @Action
  [Actions.DELETE_ORGANIZATION](orgId) {
    if (!orgId) return false;
    return ApiService.delete(`organization/${orgId}`)
      .then((data) => {
        return {
          data: {
            success: data?.status >= 200 && data?.status < 300,
          },
        };
      })
      .catch(({ response }) => {
        return response;
      });
  }

  @Action
  [Actions.LOAD_PROJECT_TYPES]() {
    if (this.projectTypes.fetched) return;
    if (this.projectTypes.promise) return this.projectTypes.promise;
    const promise = ApiService.get("taxonomies?name=projectTypes", "")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PROJECT_TYPES, {
          data,
          fetched: true,
        });
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_PROJECT_TYPES, {
          error: response.data?.error,
          fetched: false,
        });
      });
    this.context.commit(Mutations.SET_PROJECT_TYPES, {
      promise,
    });
  }

  @Action
  [Actions.LOAD_SECTORS]() {
    if (this.sectors.fetched) return;
    if (this.sectors.promise) return this.sectors.promise;
    const promise = ApiService.get("taxonomies?name=sectors", "")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SECTORS, {
          data,
          fetched: true,
        });
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_SECTORS, {
          error: response.data?.error,
          fetched: false,
        });
      });
    this.context.commit(Mutations.SET_SECTORS, { promise });
  }

  @Action
  [Actions.LOAD_DOC_TYPES](projectId) {
    return ApiService.get(
      `public/documentType` + (projectId ? `?projectId=${projectId}` : "")
    )
      .then(({ data }) => data)
      .catch(({ response }) => ({
        success: false,
        error: response.data?.error?.message || response.data?.error || response.data,
      }));
  }

  @Action
  [Actions.LOAD_DRAWING_TYPES]() {
    return ApiService.get("drawingfile?distinct=drawingType", "")
      .then(({ data }) => {
        return { success: true, data: data.result };
      })
      .catch(({ response }) => {
        return {
          success: false,
          error: response.data?.error,
        };
      });
  }

  @Action
  async [Actions.LOAD_ALL_PROJECTS](obj) {
    const user = store.getters.currentUser;
    const userId = user?._id;
    if (!user || !userId) {
      this.context.commit(Mutations.SET_PROJECTS, {
        data: new Projects(),
        error: "No valid user!",
        fetched: false,
      });
      return;
    }
    const myOrgId = user?.organization?._id;
    if (!myOrgId && !user?.superadmin) {
      this.context.commit(Mutations.SET_PROJECTS, {
        data: new Projects(),
        error: "User is not a member of any organization.",
        fetched: false,
      });
      return;
    }

    const { bookmarks, idsOnly, refetch, skip, limit, org, vis } = obj;
    const query = obj.query && encodeURIComponent(obj.query);
    const sector = obj.sector && encodeURIComponent(obj.sector);
    const type = obj.type && encodeURIComponent(obj.type);
    if (!idsOnly) {
      if (!refetch && this.projects.fetched) return;
      if (!refetch && this.projects.promise) return this.projects.promise;
    }
    const arr: string[] = [];
    const isSuper = user?.superadmin;

    let visOrgAdminQ;
    let visOrgMemberQ;
    let visOrgQ;
    let visOrgAll;

    switch (vis) {
      case "public":
        visOrgAll = `{"visibility":"${vis}"}`;
        break;
      case "organization":
        visOrgQ = !isSuper ? `,{"organization":"${myOrgId}"}` : "";
        visOrgAll = `{"$and":[{"visibility":"${vis}"}${visOrgQ}]}`;
        break;
      case "members":
        visOrgAdminQ = `,{"organization":"${myOrgId}"}`;
        visOrgMemberQ = `,{"team":"${userId}"}`;
        visOrgQ = isSuper ? "" : user.isAdmin ? visOrgAdminQ : visOrgMemberQ;
        visOrgAll = `{"$and":[{"visibility":"${vis}"}${visOrgQ}]}`;
        break;
      default:
        visOrgAdminQ = `,{"organization":"${myOrgId}"}`;
        visOrgMemberQ = `,{"$and":[{"visibility":"organization"},{"organization":"${myOrgId}"}]},{"$and":[{"visibility":"members"},{"team":"${userId}"}]}`;
        visOrgQ = isSuper ? "" : user.isAdmin ? visOrgAdminQ : visOrgMemberQ;
        visOrgAll = !isSuper ? `{"$or":[{"visibility":"public"}${visOrgQ}]}` : "{}";
        break;
    }

    const searchQ = query
      ? `,{"$or": [{ "name": { "$regex": ".*(${query}).*", "$options": "i" } }, { "description": { "$regex": ".*(${query}).*", "$options": "i" } }]}`
      : "";
    let idQ = "";
    if (bookmarks) {
      let idArr = this.bookmarks.projects;
      if (!idArr?.length) {
        await store.dispatch(Actions.READ_BOOKMARKS, {
          userId: userId,
          type: "projects",
        });
        idArr = this.bookmarks.projects;
      }
      const idStr = idArr.map((id) => `"${id}"`).join(",");
      idQ = `,{"_id":{"$in":[${idStr}]}}`;
    }
    const ands = `"$and":[${visOrgAll}${searchQ}${idQ}]`;
    arr.push(ands);
    if (org) arr.push(`"organization":"${org}"`);
    if (sector) arr.push(`"sector":"${sector}"`);
    if (type) arr.push(`"type":"${type}"`);
    const qStr = "&query={" + arr.join(",") + "}";
    const theUrl =
      'project?sort={"createdAt":-1,"name":-1}' +
      (idsOnly ? "&select=_id" : "&populate=organization") +
      (skip ? `&skip=${skip}` : "") +
      (limit ? `&limit=${limit}` : "") +
      qStr +
      // (limit ? `&limit=${limit}` : "")
      "";
    if (idsOnly) {
      return ApiService.get(theUrl)
        .then(({ data }) => {
          return { success: true, data };
        })
        .catch(({ response }) => ({ success: false, error: response.data?.error }));
    } else {
      const promise = ApiService.get(theUrl)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PROJECTS, {
            data,
            fetched: true,
          });
          return true;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_PROJECTS, {
            error: response.data?.error || "Error fetching projects.",
            fetched: false,
          });
          return false;
        });
      this.context.commit(Mutations.SET_PROJECTS, {
        promise,
      });
      return promise;
    }
  }

  @Action
  [Actions.LOAD_ALL_PROJECTS_OF_TYPE]([projectId, projectTypeName]) {
    const user = store.getters.currentUser;
    const userId = user?._id;
    if (!user || !userId) {
      return;
    }

    const myOrgId = user?.organization?._id;
    if (!myOrgId && !user?.superadmin) {
      return;
    }
    const isSuper = user?.superadmin;
    const visOrgAdminQ = `,{"organization":"${myOrgId}"}`;
    const visOrgMemberQ = `,{"$and":[{"visibility":"organization"},{"organization":"${myOrgId}"}]},{"$and":[{"visibility":"members"},{"team":"${userId}"}]}`;
    const visOrgQ = isSuper ? "" : user.isAdmin ? visOrgAdminQ : visOrgMemberQ;
    const visOrgAll = !isSuper ? `,"$or":[{"visibility":"public"}${visOrgQ}]` : "";

    projectTypeName = encodeURIComponent(projectTypeName);
    return ApiService.get(
      `project?query={"type":"${projectTypeName}","_id":{"$ne":"${projectId}"}${visOrgAll}}&populate=organization&select=name,type,sector,startDate&limit=6&sort={"createdAt":-1,"name":-1}`,
      ""
    )
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        if (response.data.error)
          this.context.commit(Mutations.SET_ERROR, response.data.error);
        return {
          error: response.data?.message || "Unknown error",
        };
      });
  }

  @Action
  [Actions.LOAD_PROJECTS_FOR_ORG](orgId) {
    if (!orgId) return false;
    return ApiService.get("organization/" + orgId + "?populate=projects", "")
      .then(({ data }) => {
        return data.projects || [];
      })
      .catch(({ response }) => {
        if (response.data.error)
          this.context.commit(Mutations.SET_ERROR, response.data.error);
        return [];
      });
  }

  @Action
  [Actions.LOAD_MY_PROJECTS]() {
    const userId = store.getters.currentUser?._id;
    if (!userId) return;
    if (this.myProjects.fetched) return;
    if (this.myProjects.promise) return this.myProjects.promise;
    const promise = ApiService.get(
      'project?populate=organization,team&query={"team":"' + userId + '"}'
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_MY_PROJECTS, {
          data,
          fetched: true,
        });
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_MY_PROJECTS, {
          error: response.data?.error,
          fetched: false,
        });
      });
    this.context.commit(Mutations.SET_MY_PROJECTS, {
      promise,
    });
  }

  @Action
  [Actions.LOAD_PROJECT_NAMES]() {
    return ApiService.get('project?select="_id,name"')
      .then(({ data }) => {
        return { success: true, data: data.result };
      })
      .catch(({ response }) => {
        return {
          success: false,
          error: response.data?.error,
        };
      });
  }

  @Action
  [Actions.LOAD_OTHER_USERS]() {
    return ApiService.get("user", "")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_OTHER_USERS, data);
      })
      .catch(({ response }) => {
        if (response.data.error)
          this.context.commit(Mutations.SET_ERROR, response.data.error);
      });
  }

  @Action
  [Actions.LOAD_OTHER_USER](userId) {
    return ApiService.get("user/" + userId + "?populate=organization", "")
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        if (response.data.error) return response.data.error;
        return response.data;
      });
  }

  @Action
  [Actions.LOAD_USERS_IN_ORG](orgId) {
    if (!orgId) return;
    return ApiService.get(`user?query={"organization":"${orgId}"}`)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        if (response.data.error) return response.data.error;
        if (response.data.message) return response.data.message;
        return response.data;
      });
  }

  @Action
  [Actions.LOAD_PROJECT](projectId) {
    return ApiService.get(`project/${projectId}?populate=team,activities,organization`)
      .then(({ data }) => ({
        success: true,
        project: data,
      }))
      .catch(({ response }) => ({
        success: false,
        error:
          response?.data?.message || response?.data?.error || response?.data || response,
      }));
  }

  @Action
  [Actions.CREATE_ORGANIZATION](orgData) {
    return ApiService.post("organization", orgData)
      .then((data) => {
        return data;
      })
      .catch(({ response }) => {
        if (response.data.error)
          this.context.commit(Mutations.SET_ERROR, response.data.error);
        else this.context.commit(Mutations.SET_ERROR, response.data);
        return false;
      });
  }

  @Action
  [Actions.UPLOAD_FILE](payload) {
    const formData = payload.formData;
    const config = { timeout: 45000 };
    return ApiService.post(
      `upload_file?overwrite=${payload.overwrite}&fileName=${payload.fileName}`,
      formData,
      config
    )
      .then((data) => {
        return data?.data;
      })
      .catch((err) => {
        if (err?.code === "ECONNABORTED" && payload.t)
          return { success: false, message: payload.t("dialog_errors.timeout") };
        return err?.response?.data || err;
      });
  }

  /* @Action
  [Actions.UPLOAD_IMAGE](formData) {
    return ApiService.post("upload_image", formData)
      .then((data) => {
        return data.data;
      })
      .catch(({ response }) => {
        return response.data;
      });
  } */

  @Action
  [Actions.UPLOAD_CSV_FILE]([formData, importType]) {
    return ApiService.post(`upload_csv_file?importType=${importType}`, formData)
      .then((data) => {
        return data.data;
      })
      .catch(({ response }) => {
        return response.data;
      });
  }

  @Action
  [Actions.CREATE_PROJECT](projData) {
    return ApiService.post("project", projData)
      .then((data) => ({ success: true, data }))
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data?.error || response.data);
        return { success: false, data: response.data };
      });
  }

  @Action
  [Actions.UPDATE_PROJECT](projData) {
    return ApiService.put("project/" + projData._id, projData)
      .then(() => true)
      .catch(({ response }) => response.data || response);
  }

  @Action
  [Actions.UPDATE_ORGANIZATION](orgData) {
    return ApiService.put("organization/" + orgData._id, orgData)
      .then(() => true)
      .catch(({ response }) => response.data || response);
  }

  // @Action
  // [Actions.LOAD_ACTIVITY](activityId) {
  //   return ApiService.get(
  //     `activity/${activityId}?populate=responsible`
  //   )
  //     .then(({ data }) => {
  //       return data;
  //     })
  //     .catch(({ response }) => {
  //       if (response.data.error) return response.data.error;
  //       return response.data;
  //     });
  // }

  @Action
  [Actions.CREATE_ACTIVITY](actData) {
    return ApiService.post("activity", actData)
      .then(() => true)
      .catch(({ response }) => response.data);
  }

  @Action
  [Actions.UPDATE_ACTIVITY](actData) {
    return ApiService.put("activity/" + actData._id, actData)
      .then(() => true)
      .catch(({ response }) => response.data);
  }

  @Action
  [Actions.DELETE_ACTIVITY](activityId) {
    if (!activityId) return false;
    return ApiService.delete(`activity/${activityId}`)
      .then((data) => {
        return {
          data: {
            success: data?.status >= 200 && data?.status < 300,
          },
        };
      })
      .catch(({ response }) => {
        return response;
      });
  }

  @Action
  [Actions.LOAD_ACTIVITY_RESPONSIBLE](activityId) {
    return ApiService.get(
      `activity/${activityId}?populate=responsible&select=responsible`
    )
      .then(({ data }) => {
        return data.responsible;
      })
      .catch(({ response }) => {
        if (response.data.error) return response.data.error;
        return response.data;
      });
  }

  @Action
  async [Actions.LOAD_FILES](obj) {
    const user = store.getters.currentUser;
    const userId = user?._id;
    if (!user || !userId) {
      this.context.commit(Mutations.SET_FILES);
      return { success: false, error: "No valid user!" };
    }
    const myOrgId = user?.organization?._id;
    if (!myOrgId && !user?.superadmin) {
      this.context.commit(Mutations.SET_FILES);
      return { success: false, error: "User is not a member of any organization." };
    }

    const { bookmarks, idsOnly, skip, limit, org, refetch } = obj;
    const query = obj.query && encodeURIComponent(obj.query);
    const type = obj.type && encodeURIComponent(obj.type);
    const docType = obj.docType && encodeURIComponent(obj.docType);
    const filter =
      query || type || docType || org || bookmarks
        ? `query=${query};type=${type};docType=${docType};org=${org};bookmarks=${bookmarks}`
        : "NO_FILTER";
    if (!idsOnly) {
      if (refetch) {
        this.context.commit(Mutations.SET_FILES);
        this.fileCache[filter] = [] as ConstructionFile[];
      } else {
        if (!this.fileCache[filter]) this.fileCache[filter] = [] as ConstructionFile[];
        // first try to return values from the cache
        if (
          this.fileCache[filter].length &&
          (this.fileCache[filter].length >= skip + limit ||
            this.fileCache[filter].length >= this.totalFileCount[filter])
        ) {
          const newArr: ConstructionFile[] = [];
          for (let i = skip; i < skip + limit; i++) {
            if (!this.fileCache[filter][i]) break;
            newArr.push(this.fileCache[filter][i]);
          }
          if (
            newArr.length > 0 &&
            (newArr.length == limit ||
              (this.totalFileCount[filter] > 0 &&
                this.fileCache[filter].length == this.totalFileCount[filter]))
          )
            return {
              success: true,
              data: { skip, limit, total: this.totalFileCount[filter], result: newArr },
            };
        }
      }
    }
    const arr: string[] = [];
    let idQ = "";
    if (bookmarks) {
      let idArr = this.bookmarks.files;
      if (!idArr?.length) {
        await store.dispatch(Actions.READ_BOOKMARKS, {
          userId: userId,
          type: "files",
        });
        idArr = this.bookmarks.files;
      }
      const idStr = idArr.map((id) => `"${id}"`).join(",");
      idQ = `"_id":{"$in":[${idStr}]}`;
    }
    if (query)
      arr.push(
        `"$or": [{ "name": { "$regex": ".*(${query}).*", "$options": "i" } },
        { "documentType": { "$regex": ".*(${query}).*", "$options": "i" } }]`
      );
    if (idQ) arr.push(idQ);
    if (type) arr.push(`"projectType":"${type}"`);
    if (docType) arr.push(`"documentType":"${docType}"`);
    if (org) arr.push(`"organization":"${org}"`);
    const qStr = "&query={" + arr.join(",") + "}";
    return ApiService.get(
      'constructionfile?sort={"createdAt":-1,"name":-1}' +
        (idsOnly
          ? "&select=_id"
          : '&populate=[{"path":"project","populate":{"path":"organization"}}]') +
        (skip ? `&skip=${skip}` : "") +
        (limit ? `&limit=${limit}` : "") +
        qStr,
      ""
    )
      .then(({ data }) => {
        if (!idsOnly)
          this.context.commit(Mutations.SET_FILES, { data, skip, limit, filter });
        return { success: true, data };
      })
      .catch(({ response }) => ({
        success: false,
        error: response.data?.error || response?.data?.message,
      }));
  }

  // note: this is the ugliest, messiest, dumbest shit I've ever written in my life
  // todo: it needs to be refactored, chopped up, and generally rethought!
  @Action
  [Actions.LOAD_DRAWINGS](obj) {
    const user = store.getters.currentUser;
    const userId = user?._id;
    if (!user || !userId) {
      return { success: false, error: "No valid user!" };
    }

    const { idsOnly, skip, limit, project, projectName, refetch, ids } = obj;
    const byIds = !!ids?.length;
    const query = byIds ? "" : obj.query && encodeURIComponent(obj.query);
    const drawingType = obj.drawingType && encodeURIComponent(obj.drawingType);
    const filter =
      query || project || projectName || drawingType
        ? `query=${query};project=${project};projectName=${projectName};drawingType=${drawingType};`
        : "NO_FILTER";
    if (!idsOnly && !byIds) {
      if (refetch) {
        this.context.commit(Mutations.SET_DRAWINGS);
        this.drawingCache[filter] = [] as DrawingFile[];
      } else {
        if (!this.drawingCache[filter]) this.drawingCache[filter] = [] as DrawingFile[];
        // first try to return values from the cache
        if (
          this.drawingCache[filter].length &&
          (this.drawingCache[filter].length >= skip + limit ||
            this.drawingCache[filter].length >= this.totalDrawingCount[filter])
        ) {
          const newArr: DrawingFile[] = [];
          for (let i = skip; i < skip + limit; i++) {
            if (!this.drawingCache[filter][i]) break;
            newArr.push(this.drawingCache[filter][i]);
          }
          if (
            newArr.length > 0 &&
            (newArr.length == limit ||
              (this.totalDrawingCount[filter] > 0 &&
                this.drawingCache[filter].length == this.totalDrawingCount[filter]))
          )
            return {
              data: {
                skip,
                limit,
                total: this.totalDrawingCount[filter],
                result: newArr,
              },
            };
        }
      }
    }
    const arr: string[] = [];
    if (query)
      arr.push(
        `"$or": [{ "name": { "$regex": ".*(${query}).*", "$options": "i" } },
        { "drawingType": { "$regex": ".*(${query}).*", "$options": "i" } },
        { "info": { "$regex": ".*(${query}).*", "$options": "i" } }]`
      );
    if (drawingType) arr.push(`"drawingType":"${drawingType}"`);
    if (project) arr.push(`"project":"${project}"`);
    if (projectName) arr.push(`"projectName":"${projectName}"`);
    if (byIds) {
      const filteredIds = ids.filter((id: string) => !!id && id != "undefined");
      const formattedIds = JSON.stringify(filteredIds);
      arr.push(`"_id":{"$in":${formattedIds}}`);
    }
    const qStr = "&query={" + arr.join(",") + "}";
    return ApiService.get(
      'drawingfile?sort={"createdAt":-1,"name":-1}' +
        (idsOnly
          ? "&select=_id"
          : '&populate=[{"path":"project","populate":{"path":"organization"}}]') +
        (skip ? `&skip=${skip}` : "") +
        (limit ? `&limit=${limit}` : "") +
        qStr,
      ""
    )
      .then(({ data }) => {
        if (byIds) {
          const sorted = ids
            .map((id) => data?.result?.find((item) => item._id === id))
            .filter((item) => !!item);
          data.result = sorted;
        }
        if (!idsOnly && !byIds)
          this.context.commit(Mutations.SET_DRAWINGS, { data, skip, limit, filter });
        return { success: true, data };
      })
      .catch(({ response }) => ({
        success: false,
        error: response.data?.error || response.error,
      }));
  }

  @Action
  async [Actions.LOAD_PROJECT_FILES](obj) {
    const user = store.getters.currentUser;
    const userId = user?._id;
    if (!user || !userId) {
      return { success: false, error: "No valid user!" };
    }

    const { bookmarks, idsOnly, projId, skip, limit } = obj;
    if (!projId) return "No project ID provided.";
    const query = obj.query && encodeURIComponent(obj.query);
    const docType = obj.docType && encodeURIComponent(obj.docType);
    const arr: string[] = [];
    let idQ = "";
    if (bookmarks) {
      let idArr = this.bookmarks.files;
      if (!idArr?.length) {
        await store.dispatch(Actions.READ_BOOKMARKS, {
          userId: userId,
          type: "files",
        });
        idArr = this.bookmarks.files;
      }
      const idStr = idArr.map((id) => `"${id}"`).join(",");
      idQ = `"_id":{"$in":[${idStr}]}`;
    }
    arr.push(`"project":"${projId}"`);
    if (query)
      arr.push(
        `"$or": [{ "name": { "$regex": ".*(${query}).*", "$options": "i" } }, { "documentType": { "$regex": ".*(${query}).*", "$options": "i" } }]`
      );
    if (idQ) arr.push(idQ);
    if (docType) arr.push(`"documentType":"${docType}"`);
    const qStr = "?query={" + arr.join(",") + "}";
    return ApiService.get(
      "constructionfile" +
        qStr +
        (idsOnly ? "&select=_id" : "") +
        (skip ? `&skip=${skip}` : "") +
        (limit ? `&limit=${limit}` : "") +
        '&sort={"createdAt":-1,"name":-1}',
      ""
    )
      .then(({ data }) => ({ success: true, data }))
      .catch(({ response }) => ({
        success: false,
        error: response.data?.message || response.data?.error || response.data,
      }));
  }

  @Action
  [Actions.LOAD_PROJECT_DRAWINGS]({ projId, skip, limit, query, drawingType }) {
    if (!projId) return "No project ID provided.";
    query = query && encodeURIComponent(query);
    drawingType = drawingType && encodeURIComponent(drawingType);
    const arr: string[] = [];
    arr.push(`"project":"${projId}"`);
    if (query)
      arr.push(
        `"$or": [{ "name": { "$regex": ".*(${query}).*", "$options": "i" } }, { "drawingType": { "$regex": ".*(${query}).*", "$options": "i" } }]`
      );
    if (drawingType) arr.push(`"drawingType":"${drawingType}"`);
    const qStr = "?query={" + arr.join(",") + "}";
    return ApiService.get(
      "drawingfile" +
        qStr +
        (skip ? `&skip=${skip}` : "") +
        (limit ? `&limit=${limit}` : ""),
      ""
    )
      .then(({ data }) => {
        return { success: true, data };
      })
      .catch(({ response }) => {
        return {
          success: false,
          error: response.data?.error,
        };
      });
  }

  // @Action
  // [Actions.POPULATE_FILES](bulkData) {
  //   return ApiService.post("constructionfile", bulkData)
  //     .then(() => true)
  //     .catch(({ response }) => {
  //       if (response.data.error)
  //         this.context.commit(Mutations.SET_ERROR, response.data.error);
  //       else this.context.commit(Mutations.SET_ERROR, response.data);
  //       return false;
  //     });
  // }

  @Action
  [Actions.STORE_FILE_DATA](fileData) {
    return ApiService.post("constructionfile", fileData)
      .then(() => true)
      .catch(({ response }) => response.data);
  }

  @Action
  [Actions.UPDATE_FILE_DATA](fileData) {
    if (!fileData?._id) return "File id missing."; //todo: i18n
    return ApiService.patch("constructionfile/" + fileData._id, fileData)
      .then(() => true)
      .catch(({ response }) => response.data);
  }

  @Action
  [Actions.STORE_DRAWING_DATA](drawingData) {
    return ApiService.post("drawingfile", drawingData)
      .then(() => true)
      .catch(({ response }) => response.data);
  }

  @Action
  [Actions.GET_ORPHAN_USERS]() {
    return ApiService.get('user?query={"organization":null}')
      .then((data) => data.data)
      .catch(({ response }) => {
        if (response.data.error)
          this.context.commit(Mutations.SET_ERROR, response.data.error);
        else this.context.commit(Mutations.SET_ERROR, response.data);
        return false;
      });
  }

  @Action
  [Actions.ADD_ADMIN](pl) {
    return ApiService.post(
      "organization/" + pl.orgId + "?addAdminId=" + pl.addAdminId,
      {}
    )
      .then((data) => data.data)
      .catch(({ response }) => response.data);
  }

  @Action
  [Actions.REMOVE_ADMIN](pl) {
    return ApiService.post(
      "organization/" + pl.orgId + "?removeAdminId=" + pl.removeAdminId,
      {}
    )
      .then((data) => data.data)
      .catch(({ response }) => response.data);
  }

  @Action
  [Actions.ADD_TEAM_MEMBER](payload) {
    return ApiService.post("project/" + payload.projectId, payload)
      .then((data) => {
        return data;
      })
      .catch(({ response }) => {
        return response;
      });
  }

  @Action
  [Actions.REMOVE_TEAM_MEMBER](payload) {
    return ApiService.delete("project/" + payload.projectId + "/" + payload.memberId)
      .then((data) => {
        return data;
      })
      .catch(({ response }) => {
        return response;
      });
  }

  @Action
  [Actions.ADD_ORG_MEMBER](payload) {
    return ApiService.post("add_org_member", payload)
      .then((data) => {
        return data;
      })
      .catch(({ response }) => {
        return response;
      });
  }

  @Action
  [Actions.REMOVE_ORG_MEMBER](userId) {
    return ApiService.post(`remove_org_member?userId=${userId}`, {})
      .then((data) => {
        return data;
      })
      .catch(({ response }) => {
        return response;
      });
  }

  @Action
  [Actions.CHANGE_ACCOUNT_TYPE](body) {
    if (!body?.userId || !body?.accountType)
      return { success: false, error: "Missing information." };
    return ApiService.post("change_account_type", body)
      .then(() => {
        return { success: true };
      })
      .catch(({ response }) => {
        return { success: false, error: response.data?.error };
      });
  }

  @Action
  [Actions.DELETE_PROJECT](projId) {
    if (!projId) return { success: false, error: "Missing project ID!" };
    return ApiService.delete(`project/${projId}`)
      .then((data) => ({ success: data?.status >= 200 && data?.status < 300 }))
      .catch(({ response }) => ({ success: false, error: response.data?.error }));
  }

  @Action
  [Actions.DELETE_PROJECTS_BULK](body) {
    if (!body?.ids?.length) return { success: false, error: "Missing project IDs!" };
    return ApiService.post("delete_projects_bulk", body)
      .then((data) => data.data)
      .catch(({ response }) => ({ success: false, error: response.data?.error }));
  }

  @Action
  [Actions.DELETE_FILE](fileId) {
    if (!fileId) return { success: false, error: "Missing file ID!" };
    return ApiService.delete(`constructionfile/${fileId}`)
      .then((data) => ({ success: data?.status >= 200 && data?.status < 300 }))
      .catch(({ response }) => ({ success: false, error: response.data?.error }));
  }

  @Action
  [Actions.DELETE_DRAWING](drawingId) {
    if (!drawingId) return { success: false, error: "Missing drawing ID!" };
    return ApiService.delete(`drawingfile/${drawingId}`)
      .then((data) => ({ success: data?.status >= 200 && data?.status < 300 }))
      .catch(({ response }) => ({ success: false, error: response.data?.error }));
  }

  @Action
  [Actions.DELETE_FILES_BULK](body) {
    if (!body?.ids?.length) return { success: false, error: "Missing file IDs!" };
    return ApiService.post("delete_files_bulk", body)
      .then((data) => data.data)
      .catch(({ response }) => ({ success: false, error: response.data?.error }));
  }

  @Action
  [Actions.DELETE_DRAWINGS_BULK](body) {
    if (!body?.ids?.length) return { success: false, error: "Missing drawing IDs!" };
    return ApiService.post("delete_drawings_bulk", body)
      .then((data) => data.data)
      .catch(({ response }) => ({ success: false, error: response.data?.error }));
  }

  @Action
  [Actions.READ_BOOKMARKS](payload) {
    return ApiService.get(`user/${payload.userId}?select=bookmarks.${payload.type}`)
      .then(({ data }) => {
        const ids =
          data?.bookmarks && data.bookmarks[payload.type]
            ? data.bookmarks[payload.type]
            : [];
        this.context.commit(Mutations.SET_BOOKMARKS, { ids, type: payload.type });
        return ids;
      })
      .catch(({ response }) => {
        console.log(Actions.READ_BOOKMARKS + " ERROR: response.data=", response.data);
        return [];
      });
  }

  @Action
  [Actions.ADD_BOOKMARKS](payload) {
    return ApiService.patch(`user/${payload.userId}?action=addBookmarks`, payload.body)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        return response.data;
      });
  }

  @Action
  [Actions.REMOVE_BOOKMARKS](payload) {
    return ApiService.patch(`user/${payload.userId}?action=removeBookmarks`, payload.body)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        return response.data;
      });
  }

  @Action
  [Actions.LOAD_PROJECT_SHARED](shareToken) {
    if (!shareToken) {
      return { success: false, error: "No share token provided." };
    }
    return ApiService.get(`api_shared/project?shareToken=${shareToken}`)
      .then(({ data }) => data)
      .catch(({ response }) => ({
        success: false,
        error: response?.data?.error || response?.data || response,
      }));
  }

  @Action
  [Actions.LOAD_PROJECT_FILES_SHARED](payload) {
    const { projId, skip, limit, shareToken } = payload;
    if (!projId) {
      return {
        success: false,
        error: "Project id is missing",
      };
    }
    const search = payload.query && encodeURIComponent(payload.query);
    const docType = payload.docType && encodeURIComponent(payload.docType);
    if (!shareToken) {
      return { success: false, error: "No share token provided." };
    }

    const skipQ = skip ? `&skip=${skip}` : "";
    const limitQ = limit ? `&limit=${limit}` : "";
    const searchQ = search ? `&search=${search}` : "";
    const docTypeQ = docType ? `&docType=${docType}` : "";
    return ApiService.get(
      `api_shared/project_files?shareToken=${shareToken}&projId=${projId}${skipQ}${limitQ}${searchQ}${docTypeQ}`
    )
      .then(({ data }) => data)
      .catch(({ response }) => ({
        success: false,
        error: response.data?.message || response.data?.error || response.data,
      }));
  }

  @Action
  [Actions.CREATE_SHARE_LINK](payload) {
    if (!payload?.projectId) {
      return { success: false, error: "No projectId provided." };
    }
    if (!payload?.resourceType) {
      return { success: false, error: "No resourceType provided." };
    }

    return ApiService.post(`create_share_link`, payload)
      .then(({ data }) => data)
      .catch(({ response }) => ({
        success: false,
        error: response?.data?.error || response?.data || response,
      }));
  }
}
