import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/projektdatabas",
        name: "projektdatabas",
        component: () => import("@/views/Projektdatabas.vue"),
      },
      {
        path: "/byggfiler",
        name: "byggfiler",
        component: () => import("@/views/Byggfiler.vue"),
      },
      {
        path: "/ritningar",
        name: "Ritningar",
        component: () => import("@/views/Ritningar.vue"),
      },
      {
        path: "/projekt/:projectId",
        name: "projekt",
        component: () => import("@/components/page-layouts/Projekt.vue"),
        children: [
          {
            path: "oversikt",
            name: "projekt-oversikt",
            component: () => import("@/views/projekt/Oversikt.vue"),
          },
          {
            path: "aktiviteter",
            name: "projekt-aktiviteter",
            component: () => import("@/views/projekt/Aktiviteter.vue"),
          },
          // {
          //   path: "budget",
          //   name: "projekt-budget",
          //   component: () => import("@/views/projekt/Budget.vue"),
          // },
          {
            path: "lardomar",
            name: "projekt-lardomar",
            component: () => import("@/views/projekt/Lardomar.vue"),
          },
          {
            path: "projektteam",
            name: "projekt-projektteam",
            component: () => import("@/views/projekt/Projektteam.vue"),
          },
          {
            path: "filer",
            name: "projekt-filer",
            component: () => import("@/views/projekt/Filer.vue"),
          },
          {
            path: "installningar",
            name: "projekt-installningar",
            component: () => import("@/views/projekt/Installningar.vue"),
          },
        ],
      },
      {
        path: "/my-organization",
        name: "my-organization",
        component: () => import("@/views/my-organization/MyOrganization.vue"),
      },
      {
        path: "/account",
        name: "account",
        component: () => import("@/components/page-layouts/Account.vue"),
        children: [
          {
            path: "overview",
            name: "account-overview",
            component: () => import("@/views/account/Overview.vue"),
          },
          {
            path: "settings",
            name: "account-settings",
            component: () => import("@/views/account/Settings.vue"),
          },
          {
            path: "change-password",
            name: "account-change-password",
            component: () => import("@/views/account/ChangePassword.vue"),
          },
        ],
      },
      {
        path: "/view-user/:userId",
        name: "view-user",
        props: true,
        component: () => import("@/components/page-layouts/ViewUser.vue"),
        children: [
          {
            path: "overview",
            name: "view-user-overview",
            component: () => import("@/views/view-user/Overview.vue"),
          },
          {
            path: "settings",
            name: "view-user-settings",
            component: () => import("@/views/view-user/Settings.vue"),
          },
          {
            path: "change-password",
            name: "view-user-change-password",
            component: () => import("@/views/view-user/ChangePassword.vue"),
          },
        ],
      },
      {
        path: "/experts",
        name: "experts",
        component: () => import("@/components/experts/ExpertsOverview.vue"),
      },
      {
        path: "/experts/:type",
        name: "expert-chat",
        component: () => import("@/components/experts/ExpertChat.vue"),
      },
    ],
  },
  {
    path: "/shared",
    name: "shared",
    component: () => import("@/layout/PublicShareLayout.vue"),
    children: [
      {
        path: "projekt/:projectId",
        name: "public-project",
        props: { shared: true, tabsToShow: ["Files"] },
        component: () => import("@/components/page-layouts/Projekt.vue"),
        children: [
          {
            path: "filer",
            name: "public-files",
            component: () => import("@/views/projekt/Filer.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      // {
      //   path: "/sign-up",
      //   name: "sign-up",
      //   component: () =>
      //     import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      // },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
      {
        path: "/password-reset-new",
        name: "password-reset-new",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordResetNew.vue"),
      },
    ],
  },
  {
    path: "/sign-up",
    name: "sign-up",
    component: () => import("@/views/crafted/authentication/MultiStepSignUp.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/frontend-version",
    name: "frontend-version",
    component: () => import("@/views/FrontendVersion.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
