import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface User {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  // password: string;
  title: string;
  phoneNo: string;
  address: string;
  avatarUrl: string;
  organization: Organization;
  orgWaiting: string;
  api_token: string;
  superadmin: boolean;
  accountType: string;
}

export interface Organization {
  name: string;
  users: User[];
  projects: string;
}

export interface UserAuthInfo {
  error: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  error = null;
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification error
   * @returns array
   */
  get getError() {
    return this.error;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.error = error;
  }

  @Mutation
  async [Mutations.SET_AUTH](user) {
    JwtService.saveToken(user.api_token);
    if (typeof user.organization === "string") {
      const { data } = await ApiService.get("organization/" + user.organization);
      user.organization = data;
    }
    this.isAuthenticated = true;
    this.user = {
      ...user,
      isAdmin: !!user.organization?.admins?.includes(user._id),
    };
    this.error = null;
  }

  @Mutation
  async [Mutations.UPDATE_SET_USER](user) {
    // note: don't completely override this.user because it contains some extra stuff
    this.user = {
      ...this.user,
      ...user,
    };
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  /* @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  } */

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.error = null;
    JwtService.destroyToken();
  }

  @Action
  [Actions.LOGIN](credentials) {
    return ApiService.post("auth/login", credentials)
      .then(({ data }) => {
        if (data.success === false) return false;
        this.context.commit(Mutations.SET_AUTH, data);
        return true;
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          (response.status >= 500 ? "Server error:<br/>" : "") +
            (response.data?.error?.message ||
              response.data?.error ||
              response.data ||
              "Unknown error")
        );
        return false;
      });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
    this.context.commit(Mutations.SET_ORGANIZATION, null);
    this.context.commit(Mutations.SET_MY_PROJECTS, {});
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("auth/register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          (response.status >= 500 ? "Server error:<br/>" : "") +
            (response.data?.error?.message ||
              response.data?.error ||
              response.data ||
              "Unknown error")
        );
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("auth/send_password_reset_email", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, null);
        return true;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.error);
        return false;
      });
  }

  @Action
  [Actions.RESET_PASSWORD](payload) {
    return ApiService.post("auth/reset_password", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, null);
        return true;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.error);
        return false;
      });
  }

  @Action
  [Actions.VERIFY_AUTH](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("auth/verify_token", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.error);
          this.context.commit(Mutations.PURGE_AUTH);
          this.context.commit(Mutations.SET_ORGANIZATION, null);
          this.context.commit(Mutations.SET_MY_PROJECTS, {});
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
      this.context.commit(Mutations.SET_ORGANIZATION, null);
      this.context.commit(Mutations.SET_MY_PROJECTS, {});
    }
  }

  @Action
  [Actions.CHANGE_PASSWORD](pwPayload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      pwPayload = {
        ...pwPayload,
        email: this.user.email,
      };
      if (pwPayload.emailOverride && this.user.superadmin)
        pwPayload.email = pwPayload.emailOverride;
      return ApiService.post("change_password", pwPayload)
        .then(() => {
          this.context.commit(Mutations.SET_ERROR, null);
          return true;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.error);
          return false;
        });
    } else {
      this.context.commit(Mutations.SET_ERROR, "User wasn't logged in");
      return false;
    }
  }

  @Action
  [Actions.UPDATE_USER](userData) {
    return ApiService.update("user", userData._id + "?populate=organization", userData)
      .then(({ data }) => {
        this.context.commit(Mutations.UPDATE_SET_USER, data);
        return true;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.error);
        return response.data || response;
      });
  }
}
