import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Expert {
  id: string;
  url: string;
  icon: string;
  color: string;
  title: string;
  path: string;
  description: string;
}

export interface ExpertsContainer {
  data: Expert[];
  error?: string;
}

@Module
export default class AiModule extends VuexModule {
  // experts: Expert[] = [];
  expertsContainer: ExpertsContainer = {
    data: [],
    error: undefined,
  };

  get getExpertsContainer(): ExpertsContainer {
    return this.expertsContainer;
  }

  @Mutation
  [Mutations.SET_EXPERTS](payload: ExpertsContainer) {
    if (payload.error) {
      this.expertsContainer.data = [];
      this.expertsContainer.error = payload.error;
    } else {
      this.expertsContainer.data = payload.data;
      this.expertsContainer.error = undefined;
    }
  }

  @Action
  async [Actions.AI_MAKE_QUERY](payload) {
    try {
      const response = await ApiService.post("ai_module/pdf/make_query", payload);
      return response?.data;
    } catch ({ response }) {
      return response.data?.errMsg ? { errMsg: response.data.errMsg } : response;
    }
  }

  @Action
  async [Actions.AI_ABORT_QUERY](payload) {
    try {
      await ApiService.post("ai_module/pdf/abort_query", payload);
      // response should contain data.answer = "true" | "false" (string)
      //  but we don't really need it
    } catch (err) {
      // stupid eslint/prettier won't let me allowEmptyCatch: true!
    }
  }

  @Action
  async [Actions.AI_GET_CHAT_HISTORY](payload) {
    try {
      const response = await ApiService.post("ai_module/pdf/get_chat_history", payload);
      return response?.data;
    } catch ({ response }) {
      return response;
    }
  }

  @Action
  async [Actions.AI_DELETE_CHAT_HISTORY](payload) {
    try {
      const response = await ApiService.post(
        "ai_module/pdf/delete_chat_history",
        payload
      );
      return response?.data;
    } catch ({ response }) {
      return response;
    }
  }

  @Action
  async [Actions.AI_SEARCH_DRAWINGS](payload) {
    try {
      const response = await ApiService.post("ai_module/drawings/search", payload);
      return response?.data;
    } catch ({ response }) {
      return response?.data || response;
    }
  }

  @Action
  async [Actions.LOAD_EXPERTS](siteLocale: string) {
    ApiService.get(`experts?locale=${siteLocale}`)
      .then(({ data }) => this.context.commit(Mutations.SET_EXPERTS, { data }))
      .catch(({ response }) =>
        this.context.commit(Mutations.SET_EXPERTS, { error: response.data.error })
      );
  }
}
