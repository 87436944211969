import { createI18n } from "vue-i18n/index";
import * as Yup from "yup";

const messages = {
  sv: {
    dialog: {
      yes: "Ja",
      no: "Nej",
      ok: "Okej",
    },
    button: {
      ok: "Okej",
      submit: "Godkänn",
      cancel: "Avbryt",
      please_wait: "Vänligen vänta...",
      remove: "Ta bort",
      back: "Tillbaka",
      next_step: "Nästa steg",
      overwrite: "Ersätt",
      uncheck: "Avmarkera",
      copy: "Kopiera",
      copied: "Kopierat!",
    },
    dashboard: {
      header_my_projects: "Mina projekt",
      subheader_my_projects: "Listar dina senaste två projekt",
      page_title: "Byggbas Dashboard",
    },
    menu: {
      menu_title: "Meny",
      home: "Hem",
      project_database: "Projektdatabas",
      construction_files: "Byggfiler",
      ritningar: "Ritningar",
      experts: "Stödmaterial",
      help_and_support: "Hjälp och support",
      get_started: "Kom igång",
      video_guide: "Videoguide",
      get_help: "Få hjälp",
      faq: "Vanliga frågor",
      contact_us: "Kontakta oss",
    },
    aside_primary: {
      project: "Projekt",
      database: "Databas",
      help_and_support: "Hjälp och support",
      profile: "Profil",
      experts: "Stödmaterial",
    },
    aside_secondary: { help_and_support: "Hjälp och support" },
    overview_database_aside: {
      database_overview: "Databasöversikt",
      title_project_database: "Projektdatabas",
      title_construction_files: "Byggfiler",
      title_ritningar: "Ritningar",
      desc_project_database: "Sök efter projekt",
      desc_construction_files: "Sök efter handlingar/filer",
      desc_ritningar: "Sök efter ritningar",
    },
    overview_projects_aside: {
      my_projects: "Mina projekt",
      my_organization: "Min organisation",
      all_projects: "Se alla projekt",
    },
    overview_experts_aside: {
      experts_overview: "Stödmaterial",
      title_experts_overview: "Översikt",
      desc_experts_overview: "Se alla dokument",
      title_experts_loading: "laddar...",
      desc_experts_loading: "laddar...",
    },
    help_drawer: {
      heading: "Lär dig använda Byggbas",
      help_subheading: "Få hjälp med Byggbas - kontakta support.",
      contact_us: "Kontakta oss",
      title_documentation_and_videos: "Dokumentation och videos",
      desc_documentation_and_videos:
        "Läs våra startguider och se på videoklipp som hjälper dig att komma igång.",
      title_faq: "Vanliga frågor",
      desc_faq: "Se vanligt återkommande frågor kring Byggbas.",
      title_contact_us: "Kontakta oss",
      desc_contact_us:
        "Har du läst dokumentationen men ändå behöver ytterligare hjälp? Kontakta oss.",
    },
    toolbar_buttons: {
      tooltip: "Lär dig använda Byggbas",
      label: "Support",
    },
    footer: {
      copyright: "Copyright Clientcave AB",
      about_us: "Om oss",
      help: "Hjälp",
      contact_us: "Kontakta oss",
    },
    header: { home: "Hem" },
    topbar: { add_project: "Lägg till projekt" },
    user_menu: {
      my_profile: "Min profil",
      settings: "Inställningar",
      my_organization: "Min organisation",
      log_out: "Logga ut ",
    },
    edit_activity_modal: {
      todo: "Att göra",
      ongoing: "Pågående",
      done: "Avklarade",
      required_title: "Namn är nödvändigt",
      required_responsible: "Ansvarig är nödvändig",
      required_deadline: "Deadline är nödvändig",
      required_keyword: "Var god lägg till ett nyckelord",
      required_status: "Status är nödvändig",
      placeholder_activity_name: "Namnge din uppgift...",
      placeholder_activity_responsible: "Välj en teammedlem",
      placeholder_activity_deadline: "Välj ett datum",
      placeholder_activity_description: "Beskriv aktiviteten.",
      placeholder_activity_status: "Välj status",
      tooltip_name: "Ge din uppgift ett namn.",
      tooltip_keyword: "Tagga aktiviteten med ett valfritt nyckelord",
      label_activity_name: "Namn på aktivitet",
      label_activity_responsible: "Ansvarig",
      select_activity_responsible: "Välj person...",
      label_activity_deadline: "Deadline",
      label_activity_description: "Uppgiftsbeskrivning",
      label_activity_keyword: "Nyckelord",
      label_activity_status: "Status",
      activity_update_positive: "Aktivitet uppdaterad",
      activity_update_negative: "Aktivitet inte uppdaterad",
    },
    wi_toolbar: {
      selected: "{n} markerade",
      search: "Sök",
      search_object_compound: "Sök {object}",
      searched_object_users: "användare",
      searched_object_construction_files: "filer",
      searched_object_projects: "projekt",
      searched_object_drawings: "ritningar",
      label_ai: "AI",
      button_ai_search: "Sök",
      tooltip_ai_search: "Sök med AI",
    },
    card_aktivitet: {
      change: "Ändra",
      remove: "Ta bort",
    },
    card_project_database: {
      title: "Projektdatabas",
      subtitle: "Lär dig från tidigare projekt.",
      heading: "Tidigare projekt",
      item1: "Sjukhus",
      item2: "Infrastruktur",
      item3: "Skolor",
      more: "Fler...",
      button_see_all: "Se alla projekt",
    },
    card_byggfiler: {
      title: "Byggfiler",
      subtitle: "Från ritningar till bygghandlingar.",
      heading: "Filer",
      item1: "Bygghandlingar",
      item2: "Utredningar",
      item3: "Detaljplaner",
      more: "Fler...",
      button_see_all: "Se alla filer",
    },
    dashboard_card_experts: {
      title: "AI Chat med stödmaterial",
      subtitle: "Få svar på frågor om bygglagar och -riktlinjer",
      heading: "Stödmaterial",
      item1: "BBR",
      item2: "Miljöbalken",
      item3: "PBF",
      more: "Fler...",
      button_see_all: "Se alla dokument",
    },
    card_dashboard_navy: {
      text1_part1: "Vi har samlat fler än",
      text1_part2: "byggfiler.",
      text2: "Ta lärdom från gamla projekt, dina och andras.",
      button_see_all_projects: "Se alla projekt",
      button_see_all_files: "Se alla filer",
    },
    card_project_preview: {
      status_ongoing: "Pågående",
      start_date_label: "Startdatum",
      budget_label: "Budget",
      budget_amount: "{n} kr",
      percentage_done: "Det här projektet är {n}% färdigt",
    },
    card_project_team: {
      remove: "Ta bort",
    },
    dropdown_select: {
      heading: "Alternativ",
      dropdown_placeholder: "Välj",
      button_reset: "Återställ",
      button_ok: "OK",
    },
    dropdown_profil: {
      update: "Uppdatera",
      modify: "Ändra",
      remove_account: "Ta bort konto",
    },
    ladda_upp_fil_modal: {
      title: "Ladda upp fil",
      label_filename: "Filnamn",
      tooltip_filename: "Skriv filens namn",
      placeholder_filename: "Skriv filens namn",
      label_year: "År",
      tooltip_year: "Skriv publikationsår",
      placeholder_year: "Skriv publikationsår",
      placeholder_handling: "Välj handling eller skapa ny",
      label_file_type: "Handling",
      label_project: "Projekt",
      label_button_new_project: "Nytt projekt",
      dropdown_placeholder: "Välj..",
      dropdown_organization_placeholder: "Välj organisation..",
      label_organization: "Organisation",
      label_project_type: "Projekttyp",
      label_upload_file: "Ladda upp fil",
      dialog_no_file_selected: "Ingen fil vald",
      dialog_please_select_file: "Var god välj en fil",
      dialog_upload_positive: "Fil uppladdad",
      dialog_upload_negative: "Fil inte uppladdad",
      dialog_upload_size: "Fil kan inte vara större än 100 MB.",
      dialog_max_files: "Maximalt tillåtna filer är 100.",
      dialog_upload_filename_empty: "Filnamn får inte vara tomt.",
      field_label_name: "Namn",
      field_label_year: "År",
      field_label_file_type: "Handling",
      field_label_project: "Projekt",
      field_label_project_name: "Projektnamn",
      field_label_organization: "Organisation",
      field_label_project_type: "Projekttyp",
      upload_one_drive_files: "Ladda upp One Drive-filer",
      connect_with_one_drive: "Anslut med OneDrive",
    },
    edit_construction_file_modal: {
      title: "Ändra fil",
      label_filename: "Filnamn",
      tooltip_filename: "Skriv filens namn",
      placeholder_filename: "Skriv filens namn",
      label_year: "År",
      tooltip_year: "Skriv publikationsår",
      placeholder_year: "Skriv publikationsår",
      label_file_type: "Handling",
      placeholder_handling: "Välj handling eller skapa ny",
      label_url: "URL",
      tooltip_url: "Skriv PDF-filens URL",
      placeholder_url: "Skriv PDF-filens URL",
      dialog_update_positive: "Fil ändrad",
      dialog_update_negative: "Fil inte ändrad",
      dialog_update_filename_empty: "Filnamn får inte vara tomt.",
      field_label_name: "Namn",
      field_label_year: "År",
      field_label_file_type: "Handling",
      field_label_url: "URL",
    },
    new_organization_modal: {
      title: "Ny organisation",
      label_name: "Namn",
      tooltip_org: "Skriv organisationens namn",
      placeholder_org: "Skriv organisationens namn",
      field_label_name: "Namn",
      required_name: "Namn är nödvändigt",
      dialog_positive: "Ny organisation skapad",
      dialog_negative: "Fel vid skapande av organisation",
    },
    ny_aktivitet_modal: {
      title: "Lägg till ny aktivitet",
      help_1: "Vill du veta mer om hur du kan skapa / redigera aktiviteter?",
      help_2: "Läs vår hjälpguide",
      label_name: "Namn på aktivitet",
      tooltip_name: "Ge din uppgift ett namn.",
      placeholder_name: "Namnge din uppgift...",
      label_responsible: "Ansvarig",
      dropdown_placeholder_responsible: "Välj en teammedlem",
      placeholder_responsible: "Välj person...",
      label_deadline: "Deadline",
      placeholder_deadline: "Välj ett datum",
      label_desc: "Uppgiftsbeskrivning",
      placeholder_desc: "Beskriv aktiviteten.",
      label_keyword: "Nyckelord",
      tooltip_keyword: "Tagga aktiviteten med ett valfritt nyckelord",
      default_status: "Att göra",
      required_title: "Titel är nödvändig",
      required_responsible: "Ansvarig är nödvändig",
      required_deadline: "Deadline är nödvändig",
      required_keyword: "Var god lägg till ett nyckelord",
      dialog_error_project_id: "Projekt ID är null",
      dialog_error_contact_support: "Var god kontakta support",
      dialog_positive: "Ny aktivitet skapad",
      dialog_negative: "Aktivitet inte skapad",
    },
    view_activity_modal: {
      description: "Uppgiftsbeskrivning",
      responsible: "Ansvarig",
      deadline: "Deadline",
      keyword: "Nyckelord",
      status: "Status",
      status_todo: "Att göra",
      status_ongoing: "Pågående",
      status_done: "Avklarade",
    },
    new_project_modal: {
      title: "Lägg till nytt Projekt",
      step1_title: "Beskrivning",
      step1_desc: "Namnge ditt projekt",
      step2_title: "Kategori",
      step2_desc: "Välj en sektor/kategori",
      step3_title: "Inställningar",
      step3_desc: "Lägg till ditt nya projekt",
      label_name: "Projektnamn",
      tooltip_name: "Ge ditt projekt ett namn",
      label_desc: "Projektets beskrivning",
      label_vis: "Vem kan se projektet?",
      tooltip_vis: "Välj dem som kan se ditt projekt.",
      vis_label_org: "Enbart för organisationen",
      vis_desc_org: "Projektet kan ses av alla i samma organisation",
      vis_label_team: "Enbart för min projektgrupp",
      vis_desc_team: "Projektet kan enbart ses av medlemmarna i projektet.",
      vis_label_all: "Öppet för alla",
      vis_desc_all: "Projektet kan ses av alla.",
      label_sector: "Välj sektor",
      tooltip_sector: "Välj sektor för ditt projekt",
      label_type: "Projekttyp",
      dropdown_placeholder: "Välj...",
      label_org: "Organisation",
      label_budget: "Budget (kr)",
      placeholder_budget: "Planerad budget i kronor...",
      label_start_date: "Startdatum",
      field_label_name: "Namn",
      field_label_desc: "Beskrivning",
      field_label_vis: "Synlighet",
      field_label_sector: "Sektor",
      field_label_type: "Projekttyp",
      field_label_org: "Organisation",
      required_budget_positive: "måste vara ett positiv nummer",
      required_budget_integer: "måste vara ett heltal",
      field_label_budget: "Budget",
      field_label_start_date: "Startdatum",
      field_label_team: "Team",
      field_label_logo: "Logga",
      field_label_archived: "Arkiverad",
      dialog_positive: "Nytt projekt skapat",
      dialog_error_exists: "Ett projekt med det namnet existerar redan",
      dialog_error_unknown: "Okänt fel",
      dialog_negative: "Projekt inte skapat",
    },
    account: {
      n_a: "/",
      tab_profile: "Profil",
      tab_settings: "Inställningar",
      page_title: "Profilsida",
    },
    view_user: {
      n_a: "/",
      tab_profile: "Profil",
      tab_projects: "Projekt",
      page_title: "Besök användare",
    },
    auth: {
      about: "Om oss",
      help: "Hjälp",
      contact_us: "Kontakta oss",
    },
    project: {
      status_ongoing: "Pågående",
      status_archived: "Arkiverad",
      button_add_members: "Lägg till användare",
      button_new_activity: "Ny aktivitet",
      label_start_date: "Startdatum",
      budget_amount: "{currency} {amount}",
      sek: "SEK",
      label_budget: "Budget",
      label_lardomar: "Lärdomar",
      tab_overview: "Översikt",
      tab_activities: "Aktiviteter",
      tab_budget: "Budget",
      tab_lardomar: "Lärdomar",
      tab_team: "Projektteam",
      tab_files: "Filer",
      tab_settings: "Inställningar",
      page_title: "Projekt",
      error_loading: "Fel vid laddning av projektet",
    },
    sign_up_1: {
      title: "Välj typ av konto",
      tooltip_title:
        "Välj vilket typ av roll du har i ditt projekt. Om du är osäker, hör av dig till oss.",
      subtitle: "Behöver du hjälp?",
      subtitle_contact_us: "Kontakta oss",
      label_type_regular: "Användarkonto",
      desc_type_regular:
        "För dig som är medlem i ett projekt, detta alternativ gäller för de allra flesta.",
      label_type_admin: "Administratör",
      desc_type_admin:
        "För dig som är projektchef eller administratör med behörighet att ändra i projekt.",
      heading_user_type: "Välj typ av användare",
      label_type1: "Full funktionalitet",
      desc_type1:
        "Gäller kommuner, offentliga bolag etc. Välj alltid denna om inget annat anges.",
      label_type2: "Begränsad funktionalitet",
      desc_type2: "Gäller enbart mindre fåmansföretag.",
    },
    sign_up_2: {
      title: "Personliga uppgifter och organisation",
      label_name: "Namn",
      placeholder_fname: "Förnamn",
      placeholder_lname: "Efternamn",
      label_org: "Organisation",
      dropdown_placeholder: "Välj...",
      option_create_new_org: "Skapa ny",
      label_new_org: "Ny organisation",
      placeholder_org: "Organisationens namn",
      label_phone: "Telefon",
      placeholder_phone: "Telefon",
      label_role: "Din roll",
      placeholder_role: "Din roll",
    },
    sign_up_3: {
      title: "Inloggningsinfo",
      label_email: "Epost",
      label_password: "Lösenord",
      msg_password:
        "Använd 8 eller fler tecken, helst en blandning av bokstäver, siffror och symboler.",
      label_password_confirm: "Bekräfta lösenord",
      msg_terms: "Jag accepterar {terms}.",
      terms: "regler och villkor",
    },
    sign_up_4: {
      title: "Klart!",
      subtitle: "Nu är du klar att börja!",
    },
    wi_pdf_embed: {
      button_close: "Stäng",
      button_show_all_pages: "Visa alla sidor",
      msg_loading: "Laddar...",
      msg_loading_failed: "Laddningen misslyckades.",
      msg_cannot_preview:
        "Filen kan inte förhandsgranskas. Vänligen ladda ner den istället.",
      msg_no_source: "No PDF source provided.",
      label_page_count: "{n} sida | {n} sidor",
    },
    pdf_chat: {
      heading_chat_with_pdf: "Chatta med PDF",
      chat_label_me: "Du",
      chat_label_ai: "AI",
      placeholder_type_your_question: "Ställ din fråga...",
      button_send: "Skicka",
      button_stop_generating: "Avbryt",
      button_reset_chat_history: "Rensa chatthistorik",
      initial_ai_msg: "Fråga mig om innehållet i PDF-dokumentet!",
      request_canceled: "avbruten",
      error_prefix: "Det har inträffat ett fel. Det tekniska felmeddelandet är:",
      formatted_error_message: "{notrl1}kod: {code}; meddelande: {message}{notrl2}",
      error_postfix: "Kontakta supporten och skriv ner felmeddelandet som du fick, tack!",
      dialog_confirm_delete_history:
        "Är du säker på att du vill radera chathistoriken med denna PDF?",
      error_no_answer_from_ai: "Inget svar mottaget från AI-modulen.",
    },
    filter_button: { button: "Filtrera" },
    loader: { loading: "Laddar..." },
    wi_drop_zone: {
      drop_file: "Släpp fil",
      drag_here: "Dra filen hit eller klicka på knappen",
      upload: "Ladda upp fil",
      selected_file: "Vald fil",
    },
    change_password: {
      title: "Ändra lösenord",
      label_old_pw: "Gammalt lösenord",
      placeholder_old_pw: "Gammalt lösenord",
      label_new_pw: "Nytt lösenord",
      placeholder_new_pw: "Nytt lösenord",
      msg_password:
        "Använd 8 eller fler tecken, helst en blandning av bokstäver, siffror och symboler.",
      label_confirm_new_pw: "Bekräfta nytt lösenord",
      placeholder_confirm_new_pw: "Bekräfta nytt lösenord",
      field_label_old_pw: "Gammalt lösenord",
      field_label_new_pw: "Nytt lösenord",
      field_label_pw_match: "Lösenord måste matcha",
      field_label_confirm_new_pw: "Bekräfta nytt lösenord",
      dialog_positive: "Lösenord har ändrats",
      dialog_negative: "Det gick inte att ändra lösenord",
    },
    account_overview: {
      title: "Uppgifter",
      button_edit: "Redigera profil",
      label_name: "Namn",
      label_org: "Organisation",
      msg_org_waiting: "Inväntar godkännande av {orgName}",
      msg_org_none: "Ingen",
      label_phone: "Tel",
      page_title: "Profilsida",
    },
    account_settings: {
      title: "Uppgifter",
      label_name: "Namn",
      placeholder_fname: "Förnamn",
      placeholder_lname: "Efternamn",
      label_org: "Organisation",
      msg_org_waiting: "Inväntar godkännande för {orgName}",
      msg_org_none: "Ingen",
      button_leave_org: "Lämna",
      button_cancel_wait: "Avbryt",
      button_join_org: "Gå med",
      button_new_org: "Ny organisation",
      label_title: "Titel",
      placeholder_title: "Titel",
      label_phone: "Tel",
      placeholder_phone: "Telefon",
      client_id: "Client ID",
      client_secret: "Client Secret",
      tenant_id: "Tenant ID",
      redirect_uri: "Redirect URI",
      heading_password: "Lösenord",
      button_change_pw: "Ändra lösenord",
      page_title: "Profilsida",
      field_label_fname: "Förnamn",
      field_label_lname: "Efternamn",
      field_label_title: "Titel",
      field_label_phone: "Tel",
      field_label_address: "Adress",
      field_label_email: "Epost",
      dialog_negative: "Fel vid uppdatering",
      dialog_applied: "Ansökt om att gå med i {orgName}",
      dialog_joined: "Gick med i {orgName}",
      dialog_error_refresh: "Oklart vad som hänt, testa ladda om sidan.",
      dialog_error_join: "Fel - kunde inte gå med i organisationen",
      dialog_confirm_leave_msg: "Är du säker på att du vill lämna din organisation?",
      dialog_error_leave: "Fel vid lämnande av organisation",
    },
    password_reset: {
      title: "Glömt lösenord?",
      subtitle: "Skriv din epost och återställ ditt lösenord.",
      label_email: "Epost",
      field_label_email: "Epost",
      dialog_positive: "Länk för att återställa lösenord har skickats.",
      dialog_negative: "Ett fel har uppstått.",
      button_try_again: "Försök igen!",
    },
    password_reset_new: {
      title: "Ange ett nytt lösenord",
      label_email: "Epost",
      label_password: "Lösenord",
      msg_password:
        "Använd 8 eller fler tecken, helst en blandning av bokstäver, siffror och symboler.",
      label_confirm_pw: "Bekräfta lösenord",
      field_label_email: "Epost",
      field_label_password: "Lösenord",
      required_pw_match: "Lösenorden måste matcha",
      field_label_confirm_pw: "Bekräfta nytt lösenord",
      dialog_positive: "Lösenordet återställt.",
      dialog_negative: "Ursäkta, ett fel har inträffat.",
      dialog_button_try_again: "Försök igen!",
    },
    sign_in: {
      title: "Logga in på Byggbas",
      subtitle_1: "Är du ny här?",
      subtitle_2: "Skapa ett konto",
      label_email: "Epost",
      label_password: "Lösenord",
      forgot_password: "Glömt lösenord?",
      button_continue: "Fortsätt",
      field_label_email: "Epost",
      field_label_pw: "Lösenord",
      dialog_positive: "Du har loggat in!",
      dialog_negative: "Fel när du försökte logga in",
      dialog_button_try_again: "Försök igen!",
    },
    error_404: {
      message: "Det finns inget här",
      button: "Återvänd hem",
    },
    error_500: {
      heading: "Systemfel",
      subheading: "Något har gått fel!{nl} Försök igen senare!",
      button: "Gå till hemsidan",
      footer_about_us: "Om oss",
      footer_help: "Hjälp",
      footer_contact_us: "Kontakta oss",
    },
    dialog_errors: {
      internal: "Tyvärr, ett internt fel har uppstått.",
      timeout: "Tyvärr, tidsgräns för begäran gick ut",
    },
    ms_sign_up: {
      step1_title: "Välj kontotyp",
      step1_sub: "Är du projektmedlem eller projektägare?",
      step2_title: "Din profilinfo",
      step2_sub: "Info om dig och din organisation",
      step3_title: "Inloggningsinfo",
      step3_sub: "Epost och lösenord",
      footer_copyright: "Copyright Clientcave AB",
      footer_about_us: "Om oss",
      footer_help: "Hjälp",
      footer_contact_us: "Kontakta oss",
      page_title: "Skapa konto",
      field_label_account_type: "Kontotyp",
      field_label_user_type: "Användartyp",
      field_label_fname: "Förnamn",
      field_label_lname: "Efternamn",
      field_label_org: "Organisation",
      field_label_new_org: "Ny organisation",
      field_label_phone: "Telefon",
      field_label_title: "Titel",
      field_label_email: "Epost",
      field_label_pw: "Lösenord",
      required_pw_match: "Lösenorden måste matcha",
      field_label_pw_conf: "Bekräfta lösenord",
      required_terms: "Måste acceptera regler och villkor.",
      dialog_positive: "Du har registrerats som en ny medlem!",
      dialog_error_exists: "En användare med samma epost finns redan.",
      dialog_negative: "Fel vid registreringen",
      button_try_again: "Försök igen!",
    },
    add_member_modal: {
      title: "Lägg till medlem",
      button_add: "Lägg till",
      dialog_negative: "Ett fel har uppstått",
    },
    my_org: {
      button_add: "Lägg till medlem",
      tab_admins: "Administratörer",
      tab_members: "Medlemmar",
      tab_regular_wl: "Vanlig väntelista",
      tab_admin_wl: "Administratörsväntelista",
      tab_unap_org: "Icke godkända organisationer",
      tab_settings: "Inställningar",
      table_ttl_admins: "Administratörer",
      table_ttl_2_admins: "Ansluten",
      table_ttl_members: "Medlemmar",
      table_ttl_2_members: "Ansluten",
      table_ttl_regular_wl: "Vanliga sökande",
      table_ttl_2_regular_wl: "Ansökt",
      table_ttl_admin_wl: "Administratörssökande",
      table_ttl_2_admin_wl: "Ansökt",
      table_ttl_unap_org: "Icke godkända organisationer",
      no_org_subtitle: "Organisationlös superadministratör",
      no_org_msg: "Du är inte medlem i en organisation.",
      page_title: "Min organisation",
      tab_all_org: "Alla organisationer",
      table_ttl_all_org: "Alla organisationer",
      button_leave: "Lämna organisationen",
      dialog_confirm_leave_msg: "Är du säker på att du vill lämna organisationen?",
      dialog_error_leave: "Ett fel har uppstått!",
    },
    org_settings: {
      heading: "Inställningar",
      label_name: "Organisationsnamn",
      placeholder_name: "Organisationsnamn",
      label_desc: "Organisationsbeskrivning",
      placeholder_desc: "Beskriv projektet här.",
      error_msg_not_admin: "Du är inte administratör för den här organisationen",
      required_name: "Organisationsnamn är nödvändigt",
      section_dangerous: "Kritisk åtgärd",
      delete_organization: "Ta bort organisation",
      dialog_updated_positive: "Organisationsbeskrivning har ändrats",
      dialog_error_exists: "En organisation med samma namn finns redan.",
      dialog_error_unknown: "Okänt fel",
      dialog_updated_negative: "Organisationen har inte uppdaterats",
      dialog_confirm_delete_org:
        "Är du säker på att du vill ta bort organisationen?\nÅtgärden går inte att ångra och kan skapa oönskade konsekvenser.",
      dialog_success_delete_org: "Organisationen borttagen",
      dialog_error_delete_org: "Fel vid borttagning av organisation",
    },
    orgs_list: {
      column_org: "Organisation",
      column_name: "Användarnamn",
      column_email: "Epost",
      column_requested: "Begäran",
      column_actions: "Åtgärder",
      dialog_tooltip_approve: "Godkänn organisation",
      dialog_tooltip_deny: "Neka organisation",
      dialog_approve_msg: "Godkänn organisationen och begär administratörsrättigheter?",
      dialog_approve_yes: "Ja, godkänn",
      dialog_approve_no: "Nej, avbryt",
      dialog_approve_positive: "Organisation godkänd",
      dialog_error_approve: "Fel vid godkännande av organisation",
      dialog_confirm_deny_msg: "Neka organisation?",
      dialog_confirm_deny_yes: "Ja, neka",
      dialog_confirm_deny_no: "Nej, avbryt",
      dialog_deny_positive: "Organisation nekad",
      dialog_deny_negative: "Fel vid nekande av organisation",
      column_nr_admins: "Administratörer",
      column_nr_members: "Medlemmar",
      column_nr_projects: "Projekt",
      column_created: "Skapad",
      delete_organization: "Ta bort",
      join_organization: "Gå med",
      dialog_confirm_delete_org:
        "Är du säker på att du vill ta bort {orgName}?Åtgärden går inte att ångra och kan skapa oönskade konsekvenser.",
      dialog_success_delete_org: "Organisationen borttagen",
      dialog_error_delete_org: "Fel vid borttagning av organisation",
      dialog_error_join: "Fel vid anslutning till organisation",
      dialog_confirm_join_msg: "Är du säker på att du vill gå med i {orgName}?",
    },
    users_list: {
      column_name: "Namn",
      column_email: "Epost",
      column_title: "Titel",
      column_created: "Skapad",
      column_type: "Typ",
      column_actions: "Åtgärd",
      tooltip_change_user_type: "Ändra användarens typ",
      tooltip_promote_admin: "Gör till administratör",
      tooltip_demote_member: "Gör till medlem",
      tooltip_approve_member: "Godkänn medlem",
      tooltip_approve_admin: "Godkänn administratör",
      tooltip_deny_member: "Neka medlem",
      tooltip_remove_member: "Ta bort medlem",
      dialog_add_admin_msg: "Lägg till i administratörsgrupp?",
      dialog_add_admin_negative: "Fel vid tilläggning av administratör",
      dialog_remove_admin_msg: "ta bort från administratörsgruppen?",
      dialog_remove_admin_negative: "Fel vid borttagning av administraör",
      dialog_approve_member_msg: "Godkänn medlem?",
      dialog_approve_member_negative: "Fel vid godkännande av medlem",
      dialog_approve_and_promote_msg: "Godkänn och gör till administratör?",
      dialog_remove_member_msg: "Ta bort medlem?",
      dialog_remove_member_negative: "Fel vid borttagning av medlem",
      dialog_reject_msg: "Avvisa användaren?",
      dialog_reject_negative: "Fel vid avvisande av medlemen",
      dialog_change_user_type: "Ändra medlemmens användartyp",
      dialog_select_user_type: "Välj en ny användartyp",
      dialog_user_type_wrong: "Fel användartyp",
      dialog_user_type_negative: "Fel vid ändring av användartyp",
    },
    add_team_member_modal: {
      heading: "Lägg till nya personer",
      subheading: "Vill du veta mer om teammedlemmar, läs vår sida om {html}.",
      faq: "vanliga frågor",
      button_add: "Lägg till",
      dialog_add_negative: "Fel vid tilläggning av teammedlem",
    },
    aktiviteter: {
      page_title: "Projekt",
      header: "Projektets aktiviteter",
      subheader: "Aktiviteter i projektet",
      status_todo: "Att göra",
      button_add: "Lägg till ny aktivitet",
      status_ongoing: "Pågående",
      status_done: "Avklarade",
      // button_actions: "Fixa",
      button_edit: "Ändra",
      button_remove: "Ta bort",
      column_activity: "Aktivitet",
      column_keyword: "Nyckelord",
      column_deadline: "Deadline",
      column_responsible: "Ansvarig",
      column_status: "Status",
      column_actions: "Åtgärd",
      dialog_remove_msg: "Vill du ta bort aktiviteten?",
      dialog_remove_positive: "Aktiviteten har tagits bort",
      dialog_remove_negative: "Aktiviteten har inte tagits bort.",
      dialog_error: "Ett fel har uppstått",
    },
    budget: {
      heading: "Projektets budget",
      label_status: "Nuvarande status",
      status_amount: "{total} kr",
      label_budget: "Budget",
      spent_msg: "{spent} kr spenderat av {total} kr",
      notes: "Anteckningar budget",
      placeholder_notes: "Här kan du skriva anteckningar som berör budget.",
      label_manage_budget: "Hantera Budget",
      page_title: "Projekt",
      dialog_budget_update_positive: "Budget uppdaterad",
      dialog_budget_update_negative: "Kunde inte uppdatera budget",
      dialog_error_unknown: "Okänt fel",
    },
    lardomar: {
      heading: "Projektets lärdomar",
      label_lardomar: "Lärdomar",
      notes: "Anteckningar projekt",
      placeholder_notes: "Här kan du skriva anteckningar som berör projektet.",
      page_title: "Projekt",
      dialog_lardomar_update_positive: "Lärdomar uppdaterade",
      dialog_lardomar_update_negative: "Kunde inte uppdatera lärdomar",
      dialog_error_unknown: "Okänt fel",
    },
    projektteam: {
      page_title: "Projekt",
      dialog_remove_msg: "Vill du ta bort användaren?",
      dialog_remove_positive: "Användaren har tagits bort",
      dialog_remove_negative: "Användaren har inte tagits bort",
      dialog_error: "Ett fel har uppstått",
    },
    filer: {
      msg_no_files_found: "Inga filer hittade",
      n_files_removed:
        "Inga filer har tagits bort. | 1 fil har tagits bort. | {n} filer har tagits bort.",
      dialog_q_remove_n_files: "Vill du ta bort 1 fil? | Vill du ta bort {n} filer?",
      dialog_q_remove_file: "Vill du ta bort {name}?",
      page_title: "Projekt",
      button_upload: "Ladda upp",
      button_share: "Dela",
      // button_actions: "Fixa",
      button_open: "Öppna",
      button_edit: "Ändra",
      button_download: "Ladda ner",
      button_remove: "Ta bort",
      column_name: "Filnamn",
      column_type: "Handling",
      column_year: "År",
      column_actions: "Åtgärder",
      dialog_file_remove_positive: "Filen har tagits bort",
      dialog_file_remove_negative: "Filen har inte tagits bort.",
      dialog_files_remove_negative: "Inga filer har tagits bort.",
      dialog_error: "Ett fel har uppstått",
    },
    proj_settings: {
      heading: "Projektinställningar",
      label_name: "Projektnamn",
      placeholder_name: "Projektets namn",
      label_type: "Projekttyp",
      dropdown_placeholder: "Välj...",
      type_none: "Ingen...",
      label_desc: "Projektets beskrivning",
      placeholder_desc: "Beskriv projektet här.",
      label_start_date: "Startdatum",
      placeholder_date: "Välj ett datum",
      label_visibility: "Synlighet",
      vis_public: "Publik",
      vis_org: "Endast organisationen",
      vis_team: "Endast teammedlemmar",
      label_archive: "Arkivera projektet?",
      label_archived: "Arkiverad",
      label_active: "Aktiv",
      error_msg_not_admin: "Du är inte administratör i det här projektets organisation.",
      page_title: "Projekt",
      required_name: "Projektnamn är nödvändigt",
      required_type: "Projekttyp är nödvändig",
      required_start_date: "Startdatum är nödvändigt",
      dialog_updated_positive: "Projektdetaljerna har uppdaterats",
      dialog_error_exists: "Ett projekt med samma namn finns redan.",
      dialog_error_unknown: "Okänt fel",
      dialog_updated_negative: "Projektet har inte uppdaterats",
    },
    proj_overview: {
      header: "Liknande projekt",
      button_see_all: "Se alla projekt",
      // button_actions: "Fixa",
      button_open: "Öppna",
      button_edit: "Ändra",
      button_remove: "Ta bort",
      page_title: "Projekt",
      columns_project: "Projekt",
      columns_sector: "Sektor",
      columns_type: "Projekttyp",
      columns_org: "Organisation",
      columns_date: "Startdatum",
      columns_actions: "Åtgärder",
    },
    change_other_password: {
      title: "Ändra lösenord",
      label_old_pw: "Gammalt lösenord",
      placeholder_old_pw: "Gammalt lösenord",
      label_new_pw: "Nytt lösenord",
      placeholder_new_pw: "Nytt lösenord",
      msg_password:
        "Använd 8 eller fler tecken, helst en blandning av bokstäver, siffror och symboler.",
      label_confirm_new_pw: "Godkänn nytt lösenord",
      placeholder_confirm_new_pw: "Godkänn nytt lösenord",
      field_label_old_pw: "Gammalt lösenord",
      field_label_new_pw: "Nytt lösenord",
      field_label_pw_match: "Lösenord måste matcha",
      field_label_confirm_new_pw: "Godkänn nytt lösenord",
      dialog_positive: "Lösenord uppdaterat",
      dialog_negative: "Det gick inte att ändra lösenord",
    },
    overview_other_user: {
      header: "Uppgifter",
      button_edit: "Redigera profil",
      label_name: "Namn",
      label_org: "Organisation",
      org_none: "Ingen",
      label_phone: "Tel",
      page_title: "Se profil",
    },
    settings_other_user: {
      title: "Ändra användarinformation",
      label_name: "Namn",
      placeholder_fname: "Förnamn",
      placeholder_lname: "Efternamn",
      label_org: "Organisation",
      msg_org_none: "Ingen",
      button_leave_org: "Lämna",
      button_join_org: "Gå med",
      button_new_org: "Ny organisation",
      label_title: "Titel",
      placeholder_title: "Titel",
      label_phone: "Tel",
      placeholder_phone: "Telefon",
      heading_password: "Lösenord",
      button_change_pw: "Ändra lösenord",
      msg_access_denied: "Tillträde nekat",
      page_title: "Se profil",
      field_label_fname: "Förnamn",
      field_label_lname: "Efternamn",
      field_label_title: "Titel",
      field_label_phone: "Telefon",
      field_label_address: "Adress",
      field_label_email: "Epost",
      dialog_negative: "Fel vid uppdatering av användarinformation",
      dialog_joined: "Ansluten till organisationen {orgName}",
      dialog_error_refresh: "Ett fel har uppstått, vänligen ladda om sidan.",
      dialog_error_join: "Fel vid anslutning till organisation",
      dialog_confirm_leave_msg:
        "Är du säker på att du vill lämna nuvarande organisation?",
      dialog_error_leave: "Ett fel har uppstått vid utträde ur organisationen",
    },
    projektdatabas: {
      button_add_project: "Lägg till projekt",
      tooltip_add_project: "Lägg till projekt",
      button_upload_csv: "Ladda upp CSV",
      // button_actions: "Fixa",
      button_open: "Öppna",
      button_edit: "Ändra",
      button_remove: "Ta bort",
      button_bookmark: "Bokmärk",
      button_unbookmark: "Ta bort bokmärke",
      page_title: "Projektdatabas",
      msg_no_projects_found: "Inga projekt hittade",
      column_project: "Projekt",
      column_sector: "Sektor",
      column_type: "Projekttyp",
      column_org: "Organisation",
      column_start_date: "Startdatum",
      column_action: "Åtgärd",
      filter_bookmarks: "Mina bokmärken",
      filter_vis_label: "Synlighet",
      filter_vis_public: "Publik",
      filter_vis_org: "Organisation",
      filter_vis_members: "Teammedlemmar",
      dialog_delete_one_q: "Vill du ta bort {name}?",
      dialog_delete_one_positive: "Projektet har tagits bort",
      dialog_delete_one_negative: "Projektet har inte tagits bort",
      dialog_error: "Ett fel har uppstått",
      dialog_delete_many_q: "Vill du ta bort {n} projekt?",
      dialog_delete_many_positive: "{n} projekt har tagits bort",
      dialog_delete_many_negative: "Inga projekt har tagits bort",
      dialog_msg_err_org: "Organisationfel",
      dialog_msg_err_validation: "Bekräftelsefel",
      dialog_msg_err_write: "Skrivfel",
      dialog_import_negative: "Inga projekt har importerats",
      dialog_import_positive: "Alla projekt har importerats",
      dialog_import_partial: "Projekten har delvis importerats",
    },
    byggfiler: {
      msg_no_files_found: "Inga filer hittade",
      button_upload: "Ladda upp",
      button_upload_csv: "Ladda upp CSV",
      // button_actions: "Fixa",
      button_open: "Öppna",
      button_download: "Ladda ner",
      button_remove: "Ta bort",
      button_bookmark: "Bokmärk",
      page_title: "Byggfiler och handlingar - Databas",
      column_name: "Filnamn",
      column_proj_type: "Projekttyp",
      column_doc_type: "Handling",
      column_org: "Organisation",
      column_year: "År",
      column_project: "Projekt",
      column_actions: "Åtgärder",
      filter_bookmarks: "Mina bokmärken",
      dialog_msg_err_validation: "Bekräftelsefel",
      dialog_msg_err_write: "Skrivfel",
      dialog_import_negative: "Inga filer har importerats",
      dialog_import_positive: "Alla filer har importerats",
      dialog_import_partial: "Filerna har delvis importerats",
      dialog_delete_one_q: "Vill du ta bort {name}?",
      dialog_delete_one_positive: "Filen har tagits bort",
      dialog_delete_one_negative: "Filen har inte tagits bort.",
      dialog_error: "Ett fel har uppstått",
      dialog_delete_many_q: "Vill du ta bort 1 fil? | Vill du ta bort {n} filer?",
      dialog_delete_many_positive:
        "Inga filer har tagits bort. | 1 fil har tagits bort. | {n} filer har tagits bort.",
      dialog_delete_many_negative: "Inga filer har tagits bort.",
    },
    ritningar: {
      button_upload: "Ladda upp",
      button_upload_csv: "Ladda upp CSV",
      // button_actions: "Fixa",
      button_open: "Öppna",
      button_download: "Ladda ner",
      button_remove: "Ta bort",
      page_title: "Ritningar - databas",
      column_name: "Filnamn",
      column_project: "Projekt",
      column_type: "Typ",
      column_src: "Källfil",
      column_info: "Info",
      column_actions: "Åtgärd",
      msg_no_items_found: "No drawings found",
      msg_ai_search_error: "AI search error",
      dialog_msg_err_validation: "Bekräftelsefel",
      dialog_msg_err_write: "Skrivfel",
      dialog_import_negative: "Inga filer har importerats",
      dialog_import_positive: "Alla filer har importerats",
      dialog_import_partial: "Filerna har delvis importerats",
      dialog_delete_one_q: "Vill du ta bort {name}?",
      dialog_delete_one_positive: "Filen har tagits bort",
      dialog_delete_one_negative: "Filen har inte tagits bort.",
      dialog_error: "Ett fel har uppstått",
      dialog_delete_many_q: "Vill du ta bort 1 fil? | Vill du ta bort {n} filer?",
      dialog_delete_many_positive:
        "Inga filer har tagits bort. | 1 fil har tagits bort. | {n} filer har tagits bort.",
      dialog_delete_many_negative: "Inga filer har tagits bort.",
    },
    wi_gallery: {
      title: "Galleri",
    },
    multi_select_actions: {
      button_label: "Åtgärder",
      action_uncheck: "Avmarkera",
      button_remove: "Ta bort",
      button_bookmark: "Bokmärk",
      button_unbookmark: "Ta bort bokmärke",
    },
    share_link_modal: {
      title: "Publik delningslänk har skapats",
      subtitle: "Kopiera delningslänken och skicka den till en extern användare.",
      title_error: "Publik delningslänk har inte skapats",
      subtitle_error: "Ett okänt fel har uppstått",
    },
    experts: {
      page_title: "Stödmaterial",
    },
    expert_chat: {
      page_title_type_invalid: "Kunde inte ladda dokumentet",
      button_go_back_to_experts: "Tillbaka till alla dokument",
    },
    card_expert: {
      button_cta: "Öppna",
    },
  },
  en: {
    dialog: {
      yes: "Ja",
      no: "Nej",
      ok: "Okej",
    },
    button: {
      ok: "Ok",
      submit: "Skicka",
      cancel: "Avbryt",
      please_wait: "Vänligen vänta...",
      remove: "Ta bort",
      back: "Tillbaka",
      next_step: "Nästa steg",
      overwrite: "Ersätt",
      uncheck: "Avmarkera",
      copy: "Kopiera",
      copied: "Kopierad!",
    },
    dashboard: {
      header_my_projects: "Mina projekt",
      subheader_my_projects: "Listar dina senaste två projekt",
      page_title: "Byggbas Dashboard",
    },
    menu: {
      menu_title: "Meny",
      home: "Hem",
      project_database: "Projektdatabas",
      construction_files: "Byggfiler",
      ritningar: "Ritningar",
      experts: "Support material",
      help_and_support: "Hjälp och support",
      get_started: "Kom igång",
      video_guide: "Videoguide",
      get_help: "Få hjälp",
      faq: "Vanliga frågor",
      contact_us: "Kontakta oss",
    },
    aside_primary: {
      project: "Projekt",
      database: "Databas",
      help_and_support: "Hjälp och support",
      profile: "Profil",
      experts: "Support material",
    },
    aside_secondary: { help_and_support: "Hjälp och support" },
    overview_database_aside: {
      database_overview: "Databasöversikt",
      title_project_database: "Projektdatabas",
      title_construction_files: "Byggfiler",
      title_ritningar: "Ritningar",
      desc_project_database: "Sök efter projekt",
      desc_construction_files: "Sök efter handlingar/filer",
      desc_ritningar: "Sök efter ritningar",
    },
    overview_projects_aside: {
      my_projects: "Mina projekt",
      my_organization: "Min organisation",
      all_projects: "Se alla projekt",
    },
    overview_experts_aside: {
      experts_overview: "Support material",
      title_experts_overview: "Overview",
      desc_experts_overview: "Overview of all support material",
      title_experts_loading: "loading...",
      desc_experts_loading: "loading...",
    },
    help_drawer: {
      heading: "Lär dig använda Byggbas",
      help_subheading: "Få hjälp med Byggbas - Kontakta support.",
      contact_us: "Kontakta oss",
      title_documentation_and_videos: "Dokumentation och videos",
      desc_documentation_and_videos:
        "Läs våra startguider och se på videoklipp som hjälper dig att komma igång.",
      title_faq: "Vanliga frågor",
      desc_faq: "Se vanligt återkommande frågor kring Byggbas.",
      title_contact_us: "Kontakta oss",
      desc_contact_us:
        "Har du läst dokumentationen men ändå behöver ytterligare hjälp? Kontakta oss.",
    },
    toolbar_buttons: {
      tooltip: "Lär dig använda Byggbas",
      label: "Support",
    },
    footer: {
      copyright: "Copyright Clientcave AB",
      about_us: "Om oss",
      help: "Hjälp",
      contact_us: "Kontakta oss",
    },
    header: { home: "Hem" },
    topbar: { add_project: "Lägg till projekt" },
    user_menu: {
      my_profile: "Min profil",
      settings: "Inställningar",
      my_organization: "Min organisation",
      log_out: "Logga ut",
    },
    edit_activity_modal: {
      todo: "Att göra",
      ongoing: "Pågående",
      done: "Avklarade",
      required_title: "Titel är nödvändig",
      required_responsible: "Ansvarig är nödvändig",
      required_deadline: "Deadline är nödvändig",
      required_keyword: "Vänligen lägg till ett nyckelord",
      required_status: "Status är nödvändig",
      placeholder_activity_name: "Namnge din uppgift...",
      placeholder_activity_responsible: "Välj en teammedlem",
      placeholder_activity_deadline: "Välj ett datum",
      placeholder_activity_description: "Beskriv aktiviteten.",
      placeholder_activity_status: "Välj status",
      tooltip_name: "Ge din uppgift ett namn.",
      tooltip_keyword: "Tagga aktiviteten med ett valfritt nyckelord",
      label_activity_name: "Namn på aktivitet",
      label_activity_responsible: "Ansvarig",
      select_activity_responsible: "Välj person...",
      label_activity_deadline: "Deadline",
      label_activity_description: "Uppgiftsbeskrivning",
      label_activity_keyword: "Nyckelord",
      label_activity_status: "Status",
      activity_update_positive: "Uppgiften har uppdaterats",
      activity_update_negative: "Uppgiften har inte uppdaterats",
    },
    wi_toolbar: {
      selected: "{n} markerade",
      search: "Sök",
      search_object_compound: "Sök {object}",
      searched_object_users: "användare",
      searched_object_construction_files: "filer",
      searched_object_projects: "projekt",
      searched_object_drawings: "ritningar",
      label_ai: "AI",
      button_ai_search: "Sök",
      tooltip_ai_search: "Sök med AI",
    },
    card_aktivitet: {
      change: "Ändra",
      remove: "Ta bort",
    },
    card_project_database: {
      title: "Projektdatabas",
      subtitle: "Lär dig från tidigare projekt.",
      heading: "Tidigare projekt",
      item1: "Sjukhus",
      item2: "Infrastruktur",
      item3: "Skolor",
      more: "Fler...",
      button_see_all: "Se alla projekt",
    },
    card_byggfiler: {
      title: "Byggfiler",
      subtitle: "Från ritningar till bygghandlingar.",
      heading: "Filer",
      item1: "Bygghandling",
      item2: "Design filer",
      item3: "Typrum",
      more: "Fler...",
      button_see_all: "Se alla filer",
    },
    dashboard_card_experts: {
      title: "AI Chat with support material",
      subtitle: "Get answers about laws and guidelines",
      heading: "Support material",
      item1: "BBR",
      item2: "Environmental code",
      item3: "PBF",
      more: "More...",
      button_see_all: "See all support material",
    },
    card_dashboard_navy: {
      text1_part1: "Vi har samlat fler än",
      text1_part2: "byggfiler.",
      text2: "Ta lärdom från tidigare projekt, dina egna och andras.",
      button_see_all_projects: "Se alla projekt",
      button_see_all_files: "Se alla filer",
    },
    card_project_preview: {
      status_ongoing: "Pågående",
      start_date_label: "Startdatum",
      budget_label: "Budget",
      budget_amount: "{n} kr",
      percentage_done: "Det här projektet är {n}% färdigt",
    },
    card_project_team: {
      remove: "Ta bort",
    },
    dropdown_select: {
      heading: "Alternativ",
      dropdown_placeholder: "Välj",
      button_reset: "Återställ",
      button_ok: "OK",
    },
    dropdown_profil: {
      update: "Uppdatera",
      modify: "Ändra",
      remove_account: "Ta bort konto",
    },
    ladda_upp_fil_modal: {
      title: "Ladda upp fil",
      label_filename: "Filnamn",
      tooltip_filename: "Skriv filens namn",
      placeholder_filename: "Skriv filens namn",
      label_year: "År",
      tooltip_year: "Skriv publikationsår",
      placeholder_year: "Skriv publikationsår",
      placeholder_handling: "Välj handling eller skapa en ny",
      label_file_type: "Handling",
      label_project: "Projekt",
      label_button_new_project: "New project",
      dropdown_placeholder: "Välj..",
      dropdown_organization_placeholder: "Välj organisation...",
      label_organization: "Organisation",
      label_project_type: "Projekttyp",
      label_upload_file: "Ladda upp fil",
      dialog_no_file_selected: "Ingen fil vald",
      dialog_please_select_file: "Välj en fil",
      dialog_upload_positive: "Fil uppladdad",
      dialog_upload_negative: "Fil har inte laddats upp",
      dialog_upload_filename_empty: "Filnamnet kan inte vara tomt",
      field_label_name: "Namn",
      field_label_year: "År",
      field_label_file_type: "Handling",
      field_label_project: "Projekt",
      field_label_project_name: "Projektnamn",
      field_label_organization: "Organisation",
      field_label_project_type: "Projekttyp",
      label_button_one_drive: "One Drive Files",
    },
    edit_construction_file_modal: {
      title: "Ändra fil",
      label_filename: "Filnamn",
      tooltip_filename: "Ange filnamn",
      placeholder_filename: "Ange filnamn",
      label_year: "År",
      tooltip_year: "Ange utgivningsår",
      placeholder_year: "Ange utgivningsår",
      label_file_type: "Handling",
      placeholder_handling: "Välj handling eller skapa en ny",
      label_url: "URL",
      tooltip_url: "Ange filens URL",
      placeholder_url: "Ange filens URL",
      dialog_update_positive: "Filen uppdaterad",
      dialog_update_negative: "Filen har inte uppdaterats",
      dialog_update_filename_empty: "Filnamnet kan inte vara tomt",
      field_label_name: "Namn",
      field_label_year: "År",
      field_label_file_type: "Handlingstyp",
      field_label_url: "URL",
    },
    new_organization_modal: {
      title: "Ny organisation",
      label_name: "Namn",
      tooltip_org: "Skriv organisationens namn",
      placeholder_org: "Skriv organisationens namn",
      field_label_name: "Namn",
      required_name: "Namn är nödvändigt",
      dialog_positive: "Ny organisation skapad!",
      dialog_negative: "Fel vid skapandet av organisationen",
    },
    ny_aktivitet_modal: {
      title: "Lägg till ny aktivitet",
      help_1: "Vill du veta mer om hur du kan skapa / redigera aktiviteter",
      help_2: "läs våran hjälpguide",
      label_name: "Namn på aktivitet",
      tooltip_name: "Ge din uppgift ett namn.",
      placeholder_name: "Namnge din uppgift...",
      label_responsible: "Ansvarig",
      dropdown_placeholder_responsible: "Välj en teammedlem",
      placeholder_responsible: "Välj person...",
      label_deadline: "Deadline",
      placeholder_deadline: "Välj ett datum",
      label_desc: "Uppgiftsbeskrivning",
      placeholder_desc: "Beskriv aktiviteten.",
      label_keyword: "Nyckelord",
      tooltip_keyword: "Tagga aktiviteten med ett valfritt nyckelord",
      default_status: "Att göra",
      required_title: "Titel är nödvändig",
      required_responsible: "Ansvarig är nödvändig",
      required_deadline: "Deadline är nödvändig",
      required_keyword: "Vänligen lägg till ett nyckelord",
      dialog_error_project_id: "Projekt-ID är okänt",
      dialog_error_contact_support: "Vänligen kontakta supporten",
      dialog_positive: "Ny aktivitet skapad",
      dialog_negative: "Aktiviteten har inte skapats",
    },
    view_activity_modal: {
      description: "Uppgiftsbeskrivning",
      responsible: "Ansvarig",
      deadline: "Deadline",
      keyword: "Nyckelord",
      status: "Status",
      status_todo: "Att göra",
      status_ongoing: "Pågående",
      status_done: "Avklarade",
    },
    new_project_modal: {
      title: "Lägg till nytt projekt",
      step1_title: "Beskrivning",
      step1_desc: "Namnge ditt projekt",
      step2_title: "Kategori",
      step2_desc: "Välj en sektor/kategori",
      step3_title: "Inställningar",
      step3_desc: "Lägg till ditt nya projekt",
      label_name: "Projektnamn",
      tooltip_name: "Ge ditt projekt ett namn",
      label_desc: "Projektets beskrivning",
      label_vis: "Vem kan se projektet?",
      tooltip_vis: "Välj dem som kan se ditt projekt",
      vis_label_org: "Enbart för organisationen",
      vis_desc_org: "Projektet kan ses av alla i samma organisation",
      vis_label_team: "Enbart för min projektgrupp",
      vis_desc_team: "Projektet kan enbart ses av medlemmarna i projektet",
      vis_label_all: "Öppet för alla",
      vis_desc_all: "Projektet kan ses av alla",
      label_sector: "Välj sektor",
      tooltip_sector: "Välj sektor för ditt projekt",
      label_type: "Projekttyp",
      dropdown_placeholder: "Välj...",
      label_org: "Organisation",
      label_budget: "Budget (kr)",
      placeholder_budget: "Planerad budget i kronor...",
      label_start_date: "Startdatum",
      field_label_name: "Namn",
      field_label_desc: "Beskrivning",
      field_label_vis: "Synlighet",
      field_label_sector: "Sektor",
      field_label_type: "Projekttyp",
      field_label_org: "Organisation",
      required_budget_positive: "måste vara ett positiv nummer",
      required_budget_integer: "måste vara ett heltal",
      field_label_budget: "Budget",
      field_label_start_date: "Startdatum",
      field_label_team: "Team",
      field_label_logo: "Logga",
      field_label_archived: "Arkiverad",
      dialog_positive: "Projektet har skapats",
      dialog_error_exists: "Ett projekt med samma namn finns redan.",
      dialog_error_unknown: "Unknown error",
      dialog_negative: "Project not created",
    },
    account: {
      n_a: "N/A",
      tab_profile: "Profil",
      tab_settings: "Inställningar",
      page_title: "Profilsida",
    },
    view_user: {
      n_a: "N/A",
      tab_profile: "Profil",
      tab_projects: "Projekt",
      page_title: "Se användare",
    },
    auth: {
      about: "Om oss",
      help: "Hjälp",
      contact_us: "Kontakta oss",
    },
    project: {
      status_ongoing: "Pågående",
      status_archived: "Arkiverad",
      button_add_members: "Lägg till användare",
      button_new_activity: "Ny aktivitet",
      label_start_date: "Startdatum",
      budget_amount: "{currency} {amount}",
      sek: "SEK",
      label_budget: "Budget",
      label_lardomar: "Lärdomar",
      tab_overview: "Översikt",
      tab_activities: "Aktiviteter",
      tab_budget: "Budget",
      tab_lardomar: "Lärdomar",
      tab_team: "Projektteam",
      tab_files: "Filer",
      tab_settings: "Inställningar",
      page_title: "Projekt",
      error_loading: "Fel uppstod när projektet skulle laddas",
    },
    sign_up_1: {
      title: "Välj typ av konto",
      tooltip_title:
        "Välj vilket typ av roll du har i ditt projekt. Om du är osäker, hör av dig till oss.",
      subtitle: "Behöver du hjälp?",
      subtitle_contact_us: "Kontakta oss",
      label_type_regular: "User account",
      desc_type_regular:
        "För dig som är medlem i ett projekt, detta alternativ gäller för de allra flesta.",
      label_type_admin: "Administratör",
      desc_type_admin:
        "För dig som är projektchef eller administratör med behörighet att ändra i projekt.",
      heading_user_type: "Välj typ av användare",
      label_type1: "Full funktionalitet",
      desc_type1:
        "Gäller kommuner, offentliga bolag etc. Välj alltid denna om inget annat anges.",
      label_type2: "Begränsad funktionalitet",
      desc_type2: "Gäller enbart mindre fåmansföretag.",
    },
    sign_up_2: {
      title: "Personliga uppgifter och organisation",
      label_name: "Namn",
      placeholder_fname: "Förnamn",
      placeholder_lname: "Efternamn",
      label_org: "Organisation",
      dropdown_placeholder: "Välj...",
      option_create_new_org: "Skapa ny",
      label_new_org: "Ny organisation",
      placeholder_org: "Organisationens namn",
      label_phone: "Telefon",
      placeholder_phone: "Telefon",
      label_role: "Din roll",
      placeholder_role: "Din roll",
    },
    sign_up_3: {
      title: "Inloggningsinfo",
      label_email: "Epost",
      label_password: "Lösenord",
      msg_password:
        "Använd 8 eller fler tecken, helst en blandning av bokstäver, siffror och symboler.",
      label_password_confirm: "Bekräfta lösenord",
      msg_terms: "Jag accepterar {terms}.",
      terms: "regler och villkor",
    },
    sign_up_4: {
      title: "Klart!",
      subtitle: "Nu är du klar att börja!",
    },
    wi_pdf_embed: {
      button_close: "Stäng",
      button_show_all_pages: "Visa alla sidor",
      msg_loading: "Laddar...",
      msg_loading_failed: "Laddningen misslyckades.",
      msg_cannot_preview:
        "Filen kan inte förhandsgranskas. Vänligen ladda ner den istället!",
      msg_no_source: "No PDF source provided.",
      label_page_count: "{n} sida | {n} sidor",
    },
    pdf_chat: {
      heading_chat_with_pdf: "Chatta med PDF",
      chat_label_me: "Du",
      chat_label_ai: "AI",
      placeholder_type_your_question: "Ställ din fråga...",
      button_send: "Skicka",
      button_stop_generating: "Avbryt",
      button_reset_chat_history: "Rensa chatthistorik",
      initial_ai_msg: "Fråga mig om innehållet i PDF-dokumentet!",
      request_canceled: "canceled",
      error_prefix: "An error has occurred. The technical error message is:",
      formatted_error_message: "{notrl1}code: {code}; message: {message}{notrl2}",
      error_postfix: "Plase contact support and copy the message you got. Thanks!",
      dialog_confirm_delete_history:
        "Är du säker på att du vill radera chathistoriken med denna PDF?",
      error_no_answer_from_ai: "No answer received from the AI module.",
    },
    filter_button: { button: "Filtrera" },
    loader: { loading: "Laddar..." },
    wi_drop_zone: {
      drop_file: "Släpp fil",
      drag_here: "Dra filen hit eller klicka på knappen",
      upload: "Ladda upp fil",
      selected_file: "Vald fil",
    },
    change_password: {
      title: "Ändra lösenord",
      label_old_pw: "Gammalt lösenord",
      placeholder_old_pw: "Gammalt lösenord",
      label_new_pw: "Nytt lösenord",
      placeholder_new_pw: "Nytt lösenord",
      msg_password:
        "Använd 8 eller fler tecken, helst en blandning av bokstäver, siffror och symboler.",
      label_confirm_new_pw: "Bekräfta nytt lösenord",
      placeholder_confirm_new_pw: "Bekräfta nytt lösenord",
      field_label_old_pw: "Gammalt lösenord",
      field_label_new_pw: "Nytt lösenord",
      field_label_pw_match: "Lösenord måste matcha",
      field_label_confirm_new_pw: "Bekräfta nytt lösenord",
      dialog_positive: "Lösenord har ändrats",
      dialog_negative: "Det gick inte att ändra lösenord",
    },
    account_overview: {
      title: "Uppgifter",
      button_edit: "Redigera profil",
      label_name: "Namn",
      label_org: "Organisation",
      msg_org_waiting: "Inväntar godkännande av {orgName}",
      msg_org_none: "Ingen",
      label_phone: "Tel",
      page_title: "Profilsida",
    },
    account_settings: {
      title: "Uppgifter",
      label_name: "Namn",
      placeholder_fname: "Förnamn",
      placeholder_lname: "Efternamn",
      label_org: "Organisation",
      msg_org_waiting: "Inväntar godkännande för {orgName}",
      msg_org_none: "Ingen",
      button_leave_org: "Lämna",
      button_cancel_wait: "Avbryt",
      button_join_org: "Gå med",
      button_new_org: "Ny organisation",
      label_title: "Titel",
      placeholder_title: "Titel",
      label_phone: "Tel",
      placeholder_phone: "Telefon",
      heading_password: "Lösenord",
      button_change_pw: "Ändra lösenord",
      page_title: "Profilsida",
      field_label_fname: "Förnamn",
      field_label_lname: "Efternamn",
      field_label_title: "Titel",
      field_label_phone: "Telefon",
      field_label_address: "Address",
      field_label_email: "Epost",
      dialog_negative: "Fel vid uppdatering av användarinställningar",
      dialog_applied: "Ansökt att gå med i {orgName}",
      dialog_joined: "Gått med i {orgName}",
      dialog_error_refresh: "Osäker på vad som har hänt, vänligen ladda om sidan.",
      dialog_error_join: "Fel vid anslutning till organisationen",
      dialog_confirm_leave_msg: "Är du säker på att du vill lämna organisationen?",
      dialog_error_leave: "Ett fel uppstod!",
    },
    password_reset: {
      title: "Glömt lösenord?",
      subtitle: "Ange din epost för att återställa lösenordet.",
      label_email: "Epost",
      field_label_email: "Epost",
      dialog_positive: "En återställningslänk för lösenord har skickats.",
      dialog_negative: "Förlåt, ett fel uppstod!",
      button_try_again: "Försök igen!",
    },
    password_reset_new: {
      title: "Ange nytt lösenord",
      label_email: "Epost",
      label_password: "Lösenord",
      msg_password:
        "Använd 8 eller fler tecken, helst en blandning av bokstäver, siffror och symboler.",
      label_confirm_pw: "Bekräfta lösenord",
      field_label_email: "Epost",
      field_label_password: "Lösenord",
      required_pw_match: "Lösenorden måste matcha",
      field_label_confirm_pw: "Bekräfta nytt lösenord",
      dialog_positive: "Lösenordet har återställts.",
      dialog_negative: "Förlåt, ett fel uppstod!",
      dialog_button_try_again: "Försök igen!",
    },
    sign_in: {
      title: "Logga in på Byggbas",
      subtitle_1: "Är du ny här?",
      subtitle_2: "Skapa ett konto",
      label_email: "Epost",
      label_password: "Lösenord",
      forgot_password: "Glömt lösenord?",
      button_continue: "Logga in",
      field_label_email: "Epost",
      field_label_pw: "Lösenord",
      dialog_positive: "Du har loggat in!",
      dialog_negative: "Ett fel uppstod vid inloggningen",
      dialog_button_try_again: "Försök igen!",
    },
    error_404: {
      message: "Det finns inget här",
      button: "Gå tillbaka hem",
    },
    error_500: {
      heading: "Systemfel",
      subheading: "Något har blivit fel!{nl}Försök igen senare.",
      button: "Gå till hemsidan",
      footer_about_us: "Om oss",
      footer_help: "Hjälp",
      footer_contact_us: "Kontakta oss",
    },
    dialog_errors: {
      internal: "Förlåt, ett internt fel har uppstått.",
      timeout: "Förlåt, begäran har gått ut.",
    },
    ms_sign_up: {
      step1_title: "Välj kontotyp",
      step1_sub: "Är du projektmedlem eller projektägare?",
      step2_title: "Din profilinfo",
      step2_sub: "Info om dig och din organisation",
      step3_title: "Inloggningsinfo",
      step3_sub: "Epost och lösenord",
      footer_copyright: "Copyright Clientcave AB",
      footer_about_us: "Om oss",
      footer_help: "Hjälp",
      footer_contact_us: "Kontakta oss",
      page_title: "Skapa konto",
      field_label_account_type: "Kontotyp",
      field_label_user_type: "Användartyp",
      field_label_fname: "Förnamn",
      field_label_lname: "Efternamn",
      field_label_org: "Organisation",
      field_label_new_org: "Ny organisation",
      field_label_phone: "Telefon",
      field_label_title: "Titel",
      field_label_email: "Epost",
      field_label_pw: "Lösenord",
      required_pw_match: "Lösenorden måste matcha",
      field_label_pw_conf: "Bekräfta lösenord",
      required_terms: "Du måste acceptera regler och villkor.",
      dialog_positive: "Du har registrerats som en ny användare!",
      dialog_error_exists: "En användare med samma epost finns redan.",
      dialog_negative: "Fel vid registrering av användare",
      button_try_again: "Försök igen!",
    },
    add_member_modal: {
      title: "Lägg till medlemmar",
      button_add: "Lägg till",
      dialog_negative: "Fel vid tilläggning av medlem",
    },
    my_org: {
      button_add: "Lägg till medlem",
      tab_admins: "Administratörer",
      tab_members: "Medlemmar",
      tab_regular_wl: "Vänlig väntelista",
      tab_admin_wl: "Administratörsväntelista",
      tab_unap_org: "Icke godkända organisationer",
      tab_settings: "Inställningar",
      table_ttl_admins: "Administratörer",
      table_ttl_2_admins: "Gått med",
      table_ttl_members: "Medlemmar",
      table_ttl_2_members: "Gått med",
      table_ttl_regular_wl: "Vanliga sökande",
      table_ttl_2_regular_wl: "Ansökt",
      table_ttl_admin_wl: "Administratörssökande",
      table_ttl_2_admin_wl: "Ansökt",
      table_ttl_unap_org: "Icke godkända organisationer",
      no_org_subtitle: "Organisationslös superadministratör",
      no_org_msg: "Du är inte medlem i någon organisation.",
      page_title: "Min organisation",
      tab_all_org: "Alla organisationer",
      table_ttl_all_org: "Alla organisationer",
      button_leave: "Lämna organisationen",
      dialog_confirm_leave_msg:
        "Är du säker på att du vill lämna din nuvarande organisation?",
      dialog_error_leave: "Fel uppstod vid lämnande av organisation",
    },
    org_settings: {
      heading: "Inställningar",
      label_name: "Organisationsnamn",
      placeholder_name: "Organisationsnamn",
      label_desc: "Organisationsbeskrivning",
      placeholder_desc: "Beskriv projektet här.",
      error_msg_not_admin: "Du är inte administratör för den här organisationen",
      required_name: "Organisationsnamn är nödvändigt",
      section_dangerous: "Kritisk åtgärd",
      delete_organization: "Ta bort organisation",
      dialog_updated_positive: "Organisationsbeskrivning har ändrats",
      dialog_error_exists: "En organisation med samma namn finns redan.",
      dialog_error_unknown: "Okänt fel",
      dialog_updated_negative: "Organisationen har inte uppdaterats",
      dialog_confirm_delete_org:
        "Är du säker på att du vill ta bort organisationen?\nÅtgärden går inte att ångra och kan skapa oönskade konsekvenser.",
      dialog_success_delete_org: "Organisationen borttagen",
      dialog_error_delete_org: "Fel vid borttagning av organisation",
    },
    orgs_list: {
      column_org: "Organisation",
      column_name: "Användarnamn",
      column_email: "Epost",
      column_requested: "Begärda",
      column_actions: "Åtgärder",
      dialog_tooltip_approve: "Godkänn organisation",
      dialog_tooltip_deny: "Neka organisation",
      dialog_approve_msg: "Godkänn organisationen och begär administratörsrättigheter?",
      dialog_approve_yes: "Ja, godkänn",
      dialog_approve_no: "Nej, avbryt",
      dialog_approve_positive: "Organisation godkänd",
      dialog_error_approve: "Fel vid godkännande av organisation",
      dialog_confirm_deny_msg: "Neka organisation?",
      dialog_confirm_deny_yes: "Ja, neka",
      dialog_confirm_deny_no: "Nej, avbryt",
      dialog_deny_positive: "Organisation nekad",
      dialog_deny_negative: "Fel vid nekande av organisation",
      column_nr_admins: "Administratörer",
      column_nr_members: "Medlemmar",
      column_nr_projects: "Projekt",
      column_created: "Skapad",
      delete_organization: "Ta bort",
      join_organization: "Gå med",
      dialog_confirm_delete_org:
        "Är du säker på att du vill ta bort {orgName}?\nÅtgärden går inte att ångra och kan skapa oönskade konsekvenser.",
      dialog_success_delete_org: "Organisationen borttagen",
      dialog_error_delete_org: "Fel vid borttagning av organisation",
      dialog_error_join: "Fel vid anslutning till organisation",
      dialog_confirm_join_msg: "Är du säker på att du vill gå med i {orgName}?",
    },
    users_list: {
      column_name: "Namn",
      column_email: "Epost",
      column_title: "Titel",
      column_created: "Skapad",
      column_type: "Typ",
      column_actions: "Åtgärder",
      tooltip_change_user_type: "Ändra användartyp",
      tooltip_promote_admin: "Gör till administratör",
      tooltip_demote_member: "Gör till medlem",
      tooltip_approve_member: "Godkänn medlem",
      tooltip_approve_admin: "Godkänn administratör",
      tooltip_deny_member: "Neka medlem",
      tooltip_remove_member: "Ta bort medlem",
      dialog_add_admin_msg: "Lägg till i administratörsgrupp?",
      dialog_add_admin_negative: "Fel vid tilläggning av administratör",
      dialog_remove_admin_msg: "Ta bort från administratörsgrupp?",
      dialog_remove_admin_negative: "Fel vid borttagning av administratör",
      dialog_approve_member_msg: "Godkänn medlem?",
      dialog_approve_member_negative: "Fel vid godkännande av medlem",
      dialog_approve_and_promote_msg: "Godkänn och gör till administratör?",
      dialog_remove_member_msg: "Ta bort medlem?",
      dialog_remove_member_negative: "Fel vid borttagning av medlem",
      dialog_reject_msg: "Avvisa användare?",
      dialog_reject_negative: "Fel vid avvisande av användare",
      dialog_change_user_type: "Ändra medlems användartyp",
      dialog_select_user_type: "Välj ny användartyp",
      dialog_user_type_wrong: "Fel användartyp",
      dialog_user_type_negative: "Fel vid ändring av användartyp",
    },
    add_team_member_modal: {
      heading: "Lägg till nya personer",
      subheading: "Vill du veta mer om teammedlemmar, läs vår sida om {html}.",
      faq: "vanliga frågor",
      button_add: "Lägg till",
      dialog_add_negative: "Fel fid tilläggning av teammedlem",
    },
    aktiviteter: {
      page_title: "Projekt",
      header: "Projektets aktiviteter",
      subheader: "Aktiviteter i projektet",
      status_todo: "Att göra",
      button_add: "Lägg till ny aktivitet",
      status_ongoing: "Pågående",
      status_done: "Avklarade",
      // button_actions: "Fixa",
      button_edit: "Ändra",
      button_remove: "Ta bort",
      column_activity: "Aktivitet",
      column_keyword: "Nyckelord",
      column_deadline: "Deadline",
      column_responsible: "Ansvarig",
      column_status: "Status",
      column_actions: "Åtgärder",
      dialog_remove_msg: "Vill du ta bort aktiviteten?",
      dialog_remove_positive: "Aktiviteten har tagits bort",
      dialog_remove_negative: "Aktiviteten har inte tagits bort.",
      dialog_error: "Ett fel har uppstått",
    },
    budget: {
      heading: "Projektets budget",
      label_status: "Nuvarande status",
      status_amount: "{total} kr",
      label_budget: "Budget",
      spent_msg: "{spent} kr spenderat av {total} kr",
      notes: "Anteckningar budget",
      placeholder_notes: "Här kan du skriva anteckningar som berör budget.",
      label_manage_budget: "Hantera Budget",
      page_title: "Projekt",
      dialog_budget_update_positive: "Budget har uppdaterats.",
      dialog_budget_update_negative: "Kunde inte uppdaterat budget.",
      dialog_error_unknown: "Okänt fel",
    },
    lardomar: {
      heading: "Projektets lärdomar",
      label_lardomar: "Lärdomar",
      notes: "Anteckningar projekt",
      placeholder_notes: "Här kan du skriva anteckningar som berör projektet.",
      page_title: "Projekt",
      dialog_lardomar_update_positive: "Lärdomar uppdaterade",
      dialog_lardomar_update_negative: "Kunde inte uppdatera lärdomar",
      dialog_error_unknown: "Okänt fel",
    },
    projektteam: {
      page_title: "Projekt",
      dialog_remove_msg: "Vill du ta bort användaren?",
      dialog_remove_positive: "Användaren borttagen",
      dialog_remove_negative: "Användaren har inte tagits bort",
      dialog_error: "Ett fel har inträffat",
    },
    filer: {
      msg_no_files_found: "Inga filer hittade",
      n_files_removed: "Inga filer borttagna. | 1 fil borttagen. | {n} filer borttagna.",
      dialog_q_remove_n_files: "Vill du ta bort 1 fil? | Vill du ta bort {n} filer?",
      dialog_q_remove_file: "Vill du ta bort {name}?",
      page_title: "Projekt",
      button_upload: "Ladda upp",
      button_share: "Dela",
      // button_actions: "Fixa",
      button_open: "Öppna",
      button_edit: "Ändra",
      button_download: "Ladda ner",
      button_remove: "ta bort",
      column_name: "Filnamn",
      column_type: "Handlingstyp",
      column_year: "År",
      column_actions: "Åtgärd",
      dialog_file_remove_positive: "Fil borttagen.",
      dialog_file_remove_negative: "Fil inte borttagen.",
      dialog_files_remove_negative: "Inga filer borttagna.",
      dialog_error: "Ett fel har inträffat",
    },
    proj_settings: {
      heading: "Projektinställningar",
      label_name: "Projektnamn",
      placeholder_name: "Projektets namn",
      label_type: "Projekttyp",
      dropdown_placeholder: "Välj..",
      type_none: "Ingen...",
      label_desc: "Projektets beskrivning",
      placeholder_desc: "Beskriv projektet här.",
      label_start_date: "Startdatum",
      placeholder_date: "Välj ett datum",
      label_visibility: "Synlighet",
      vis_public: "Publik",
      vis_org: "Endast organisationen",
      vis_team: "Endast teammedlemmar",
      label_archive: "Arkivera projektet?",
      label_archived: "Arkiverad",
      label_active: "Aktiv",
      error_msg_not_admin:
        "Du är inte administratör för det här projektets organisation.",
      page_title: "Projekt",
      required_name: "Projektnamn är nödvändigt",
      required_type: "Projekttyp är nödvändig",
      required_start_date: "Startdatum är nödvändigt",
      dialog_updated_positive: "Projektdetaljerna har uppdaterats",
      dialog_error_exists: "Ett projekt med samma namn finns redan.",
      dialog_error_unknown: "Okänt fel",
      dialog_updated_negative: "Projektet har inte uppdaterats",
    },
    proj_overview: {
      header: "Liknande projekt",
      button_see_all: "Se alla projekt",
      // button_actions: "Fixa",
      button_open: "Öppna",
      button_edit: "Ändra",
      button_remove: "Ta bort",
      page_title: "Projekt",
      columns_project: "Projekt",
      columns_sector: "Sektor",
      columns_type: "Projekttyp",
      columns_org: "Organisation",
      columns_date: "Startdatum",
      columns_actions: "Årgärder",
    },
    change_other_password: {
      title: "Ändra lösenord",
      label_old_pw: "Gammalt lösenord",
      placeholder_old_pw: "Gammalt lösenord",
      label_new_pw: "Nytt lösenord",
      placeholder_new_pw: "Nytt lösenord",
      msg_password:
        "Använd 8 eller fler tecken, helst en blandning av bokstäver, siffror och symboler.",
      label_confirm_new_pw: "Godkänn nytt lösenord",
      placeholder_confirm_new_pw: "Godkänn nytt lösenord",
      field_label_old_pw: "Gammalt lösenord",
      field_label_new_pw: "Nytt lösenord",
      field_label_pw_match: "Lösenord måste matcha",
      field_label_confirm_new_pw: "Godkänn nytt lösenord",
      dialog_positive: "Lösenord uppdaterat",
      dialog_negative: "Det gick inte att ändra lösenord",
    },
    overview_other_user: {
      header: "Uppgifter",
      button_edit: "Redigera profil",
      label_name: "Namn",
      label_org: "Organisation",
      org_none: "Ingen",
      label_phone: "Tel",
      page_title: "Se användare",
    },
    settings_other_user: {
      title: "Ändra användarinformation",
      label_name: "Namn",
      placeholder_fname: "Förnamn",
      placeholder_lname: "Efternamn",
      label_org: "Organisation",
      msg_org_none: "Ingen",
      button_leave_org: "Lämna",
      button_join_org: "Gå med",
      button_new_org: "Ny organisation",
      label_title: "Titel",
      placeholder_title: "Titel",
      label_phone: "Tel",
      placeholder_phone: "Telefon",
      heading_password: "Lösenord",
      button_change_pw: "Ändra lösenord",
      msg_access_denied: "Tillträde förbjudet",
      page_title: "Se användare",
      field_label_fname: "Förnamn",
      field_label_lname: "Efternamn",
      field_label_title: "Titel",
      field_label_phone: "Telefon",
      field_label_address: "Adress",
      field_label_email: "Epost",
      dialog_negative: "Fel vid uppdatering av användarinställningar",
      dialog_joined: "Gått med i organisationen {orgName}",
      dialog_error_refresh: "Något fel har hänt. Vänligen refresha sidan.",
      dialog_error_join: "Fel vid anslutning till organisationen",
      dialog_confirm_leave_msg:
        "Är du säker på att du vill lämna nuvarande organisation?",
      dialog_error_leave: "Fel vid lämnande av organisation",
    },
    projektdatabas: {
      button_add_project: "Lägg till projekt",
      tooltip_add_project: "Lägg till projekt",
      button_upload_csv: "Ladda upp CSV",
      // button_actions: "Actions",
      button_open: "Öppna",
      button_edit: "Ändra",
      button_remove: "Ta bort",
      button_bookmark: "Bokmärke",
      button_unbookmark: "Ta bort bokmärke",
      page_title: "Projektdatabas",
      msg_no_projects_found: "Inga projekt hittades",
      column_project: "Projekt",
      column_sector: "Sektor",
      column_type: "Projekttyp",
      column_org: "Organisation",
      column_start_date: "Startdatum",
      column_action: "Åtgärder",
      filter_bookmarks: "Mina bokmärken",
      filter_vis_label: "Synlighet",
      filter_vis_public: "Publik",
      filter_vis_org: "Organisation",
      filter_vis_members: "Teammedlemmar",
      dialog_delete_one_q: "Vill du ta bort {name}?",
      dialog_delete_one_positive: "Projektet har tagits bort",
      dialog_delete_one_negative: "Projektet har inte tagits bort.",
      dialog_error: "Ett fel har uppstått",
      dialog_delete_many_q: "Vill du ta bort {n} projekt?",
      dialog_delete_many_positive: "{n} projekt har tagits bort.",
      dialog_delete_many_negative: "Inga projekt har tagits bort.",
      dialog_msg_err_org: "Organisationsfel",
      dialog_msg_err_validation: "Bekräftelsefel",
      dialog_msg_err_write: "Skrivfel",
      dialog_import_negative: "Inga projekt importerade",
      dialog_import_positive: "Alla projekt importerade",
      dialog_import_partial: "Projekten delvis importerade",
    },
    byggfiler: {
      msg_no_files_found: "Inga filer hittade",
      button_upload: "Ladda upp",
      button_upload_csv: "Ladda upp CSV",
      // button_actions: "Actions",
      button_open: "Öppna",
      button_download: "Ladda ner",
      button_remove: "Ta bort",
      button_bookmark: "Bokmärke",
      page_title: "Byggfiler och handlingar - Databas",
      column_name: "Filnamn",
      column_proj_type: "Projekttyp",
      column_doc_type: "Handling",
      column_org: "Organisation",
      column_year: "År",
      column_project: "Projekt",
      column_actions: "Åtgärder",
      filter_bookmarks: "Mina bokmärken",
      dialog_msg_err_validation: "Bekräftelsefel",
      dialog_msg_err_write: "Skrivfel",
      dialog_import_negative: "Inga konstruktionsfiler importerade",
      dialog_import_positive: "Alla konstruktionsfiler importerade",
      dialog_import_partial: "Konstruktionsfiler delvis importerade",
      dialog_delete_one_q: "Vill du ta bort {name}?",
      dialog_delete_one_positive: "Filen har tagits bort",
      dialog_delete_one_negative: "Filen har inte tagits bort",
      dialog_error: "Ett fel har uppstått",
      dialog_delete_many_q: "Vill du ta bort 1 fil? | Vill du ta bort {n} filer?",
      dialog_delete_many_positive:
        "Inga filer borttagna. | 1 fil har borttagen. | {n} filer borttagna.",
      dialog_delete_many_negative: "Inga filer borttagna.",
    },
    ritningar: {
      button_upload: "Ladda upp",
      button_upload_csv: "Ladda upp CSV",
      // button_actions: "Fixa",
      button_open: "Öppna",
      button_download: "Ladda ner",
      button_remove: "Ta bort",
      page_title: "Ritningar - Databas",
      column_name: "Filnamn",
      column_project: "Projekt",
      column_type: "Typ",
      column_src: "Källfil",
      column_info: "Info",
      column_actions: "Åtgärder",
      msg_no_items_found: "No drawings found",
      msg_ai_search_error: "AI search error",
      dialog_msg_err_validation: "Bekräftelsefel",
      dialog_msg_err_write: "Skrivfel",
      dialog_import_negative: "Inga ritningar importerade",
      dialog_import_positive: "Alla ritningar importerade",
      dialog_import_partial: "Ritningar delvis importerade",
      dialog_delete_one_q: "Vill du ta bort {name}?",
      dialog_delete_one_positive: "Filen har tagits bort",
      dialog_delete_one_negative: "Filen har inte tagits bort.",
      dialog_error: "Ett fel har uppstått",
      dialog_delete_many_q: "Vill du ta bort 1 fil? | Vill du ta bort {n} filer?",
      dialog_delete_many_positive:
        "Inga filer borttagna. | 1 fil borttagen. | {n} filer borttagna.",
      dialog_delete_many_negative: "Inga filer borttagna.",
    },
    wi_gallery: {
      title: "Galleri",
    },
    multi_select_actions: {
      button_label: "Åtgärder",
      action_uncheck: "Avmarkera",
      button_remove: "Ta bort",
      button_bookmark: "Lägg till bokmärke",
      button_unbookmark: "Ta bort bokmärke",
    },
    share_link_modal: {
      title: "Publik delningslänk skapad",
      subtitle: "Kopiera delningslänken och skicka den till en extern användare.",
      title_error: "Publik delningslänk har inte skapats",
      subtitle_error: "Ett okänt fel har uppstått",
    },
    experts: {
      page_title: "Support material",
    },
    expert_chat: {
      page_title_type_invalid: "Could not load the material",
      button_go_back_to_experts: "Back to support material",
    },
    card_expert: {
      button_cta: "Open",
    },
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "sv",
  fallbackLocale: "en",
  globalInjection: true,
  messages,
});

Yup.setLocale({
  mixed: {
    required: "${label} är nödvändig(t)",
  },
  string: {
    email: "${label} måste vara en giltlig epost",
    // email: "${label} must be a valid email",
  },
});

export default i18n;
